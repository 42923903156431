const Post = () => {
  return (
    <>
      <div className='bold-text col-lg-6 col-md-6 col-sm-12'>
        <h6 style={{ backgroundColor: 'yellow' }}>
          Please use your own bags. 
          <br /> If you cannot buy them, please contact our office and we will
          send you.
        </h6>{' '}
      </div>
      {/* <!-- Mission Section --> */}
      <section className='mission-section clearfix'>
        <div className='auto-container'>
          <div className='row clearfix'>
            {/* <!-- Image Column --> */}
            <div className='image-column col-lg-6 col-md-12 col-sm-12'>
              <div className='inner-column'>
                <div
                  className='image wow fadeInLeft'
                  data-wow-delay='0ms'
                  data-wow-duration='1500ms'
                >
                  <img
                    src='images/resource/ziplock.png'
                    alt=''
                    style={{
                      borderRadius: '15px',
                      boxShadow: '7px 5px 5px 0px rgba(0,0,0,0.5)',
                    }}
                  />
                </div>
              </div>
            </div>

            {/* <!-- Content Column --> */}
            <div className='content-column col-lg-6 col-md-12 col-sm-12'>
              <div className='inner-column'>
                {/* <!-- Sec Title --> */}
                <div className='sec-title'>
                  {/* <div className='title'>AVIGENICS</div> */}
                  <h2>Prepare self-sealing bag and post</h2>
                </div>
                <div className='bold-text'>
                  Please enter Ring No, Species Name and Order Number on lable
                  as shown in image. <br />
                  If you cannot attach lable to zip lock, please put the paper
                  inside self-sealing bag along with samples.
                  <br />
                  If you cannot source self-sealing bags for yourself, please
                  fold samples in a news paper etc (we do not recommend for more
                  than 3 samples as there will be possibility of contamination).
                  <br />
                  Please contact us with your postal address and number of
                  samples you are willing to post, we will send the bags to you.
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='mission-section clearfix'>
        <div className='auto-container'>
          <div className='row clearfix'>
            {/* <!-- Image Column --> */}
            <div className='image-column col-lg-6 col-md-12 col-sm-12'>
              <div className='inner-column'>
                <div
                  className='image wow fadeInLeft'
                  data-wow-delay='0ms'
                  data-wow-duration='1500ms'
                >
                  <img
                    src='images/resource/post1.png'
                    alt=''
                    style={{
                      borderRadius: '15px',
                      boxShadow: '7px 5px 5px 0px rgba(0,0,0,0.5)',
                    }}
                  />
                </div>
              </div>
            </div>

            {/* <!-- Content Column --> */}
            <div className='content-column col-lg-6 col-md-12 col-sm-12'>
              <div className='inner-column'>
                {/* <!-- Sec Title --> */}
                <div className='sec-title'>
                  {/* <div className='title'>AVIGENICS</div> */}
                  <h2>Click on Post button</h2>
                </div>
                <div className='bold-text'>
                  Click on the button Post as shown in image. <br />
                  Enter details of postage/ courier.
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Services Section Three --> */}
    </>
  )
}

export default Post
