import { useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Button, Modal, Image, Alert, Form } from 'react-bootstrap'
import { useOrder } from '../config/OrderContext'
import { useGlobal } from '../config/GlobalContext'

const StripePay = () => {
  const { currentUser, setCallStripe } = useOrder()
  const { payDetails } = useGlobal()

  // const stripePromise = loadStripe(
  //   'pk_live_51Io9pmEUu2gJx2DttqRbwesKsLeUiQFY5bNDX55D1KJxyQ7jAijUEPy6uNviQPU0Wjf4HnCRWpQPI9uuhRBoW8We00d8CI2Zin'
  // )
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)
  let {
    dna,
    pbfd,
    apv,
    dnaPbfd,
    dnaApv,
    pbfdApv,
    dnaPbfdApv,
    certs,
    total,
    kitsPostage,
    certsPostage,
    certsCost,
    order_id,
    batch_no,
    //   ratites,
  } = payDetails
  // console.log(payDetails)

  const [message, setMessage] = useState()
  const [showPay, setShowPay] = useState(true)

  const ProductDisplay = ({ handleClick }) => (
    <section align='left'>
      <div className='product'>
        {dna && dna.count > 0 && (
          <div className='description'>
            <h5>
              Bird DNA Test x {dna.count} ={' '}
              <span style={{ float: 'right', marginRight: '20px' }}>
                {' '}
                ${dna.cost.toFixed(2)}
              </span>
            </h5>
          </div>
        )}
        {pbfd && pbfd.count > 0 && (
          <div className='description'>
            <h5>
              PBFD Testing x {pbfd.count} ={' '}
              <span style={{ float: 'right', marginRight: '20px' }}>
                ${pbfd.cost}
              </span>
            </h5>
          </div>
        )}
        {apv && apv.cost > 0 && (
          <div className='description'>
            <h5>
              APV Testing x {apv.count} ={' '}
              <span style={{ float: 'right', marginRight: '20px' }}>
                ${apv.cost.toFixed(2)}
              </span>
            </h5>
          </div>
        )}
        {dnaPbfd && dnaPbfd.cost > 0 && (
          <div className='description'>
            <h5>
              Bird DNA & PBFD Testing x {dnaPbfd.count} ={' '}
              <span style={{ float: 'right', marginRight: '20px' }}>
                ${dnaPbfd.cost.toFixed(2)}
              </span>
            </h5>
          </div>
        )}
        {dnaApv && dnaApv.cost > 0 && (
          <div className='description'>
            <h5>
              Bird DNA & APV Testing x {dnaApv.count} ={' '}
              <span style={{ float: 'right', marginRight: '20px' }}>
                ${dnaApv.cost.toFixed(2)}
              </span>
            </h5>
          </div>
        )}
        {pbfdApv && pbfdApv.cost > 0 && (
          <div className='description'>
            <h5>
              PBFD & APV Testing x {pbfdApv.count} ={' '}
              <span style={{ float: 'right', marginRight: '20px' }}>
                ${pbfdApv.cost.toFixed(2)}
              </span>
            </h5>
          </div>
        )}
        {dnaPbfdApv && dnaPbfdApv.cost > 0 && (
          <div className='description'>
            <h5>
              Bird DNA, PBFD & APV Testing x {dnaPbfdApv.count} ={' '}
              <span style={{ float: 'right', marginRight: '20px' }}>
                ${dnaPbfdApv.cost.toFixed(2)}
              </span>
            </h5>
          </div>
        )}
        {/* {ratites && ratites.cost > 0 && (
          <div className='description'>
            <h5>
              Ratite DNA Test x {ratites.count} ={' '}
              <span style={{ float: 'right', marginRight: '20px' }}>
                ${ratites.cost.toFixed(2)}
              </span>
            </h5>
          </div>
        )} */}
        {certs && certs.cost > 0 && (
          <div className='description'>
            <h5>
              Printed Certificates x {certs.count}*={' '}
              <span style={{ float: 'right', marginRight: '20px' }}>
                ${certs.cost.toFixed(2)}
              </span>
            </h5>
          </div>
        )}
        {kitsPostage && kitsPostage > 0 ? (
          <div className='description'>
            <h6>
              Postage for Sample Kits ={' '}
              <span style={{ float: 'right', marginRight: '20px' }}>
                ${kitsPostage.toFixed(2)}
              </span>
            </h6>
          </div>
        ) : (
          ''
        )}
        {certsPostage && certsPostage > 0 ? (
          <div className='description'>
            <h6>
              Postage for Certificates={' '}
              <span style={{ float: 'right', marginRight: '20px' }}>
                ${certsPostage.toFixed(2)}
              </span>
            </h6>
          </div>
        ) : (
          ''
        )}
      </div>
      <Form.Text muted>
        * Printed Certificates are available for DNA Sexing only{' '}
      </Form.Text>
      <div align='center'>
        <br />
        <br />
        <h5>
          <strong>
            {' '}
            <span style={{ float: 'right', marginRight: '20px' }}>
              Total = ${total && total.toFixed(2)}
            </span>
          </strong>
        </h5>
      </div>
      <Button onClick={handleClick}>Checkout</Button> &nbsp;&nbsp;&nbsp;
      <Button onClick={() => setShowPay(false)}>Cancel</Button>
    </section>
  )

  const handleClick = async (event) => {
    const stripe = await stripePromise
    const response = await fetch('/api/create-checkout-session/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        dna: { cost: dna.cost * 100, quantity: dna.count },
        email: currentUser.email,
        order_id,
        batch_no,
        username: currentUser.displayName,
        shipping: { cost: certsPostage * 100, quantity: 1 },
        certs: { cost: certs.cost * 100, quantity: certs.count },
        pbfd: { cost: pbfd.cost * 100, quantity: pbfd.count },
        apv: { cost: apv.cost * 100, quantity: apv.count },
        kits: { cost: kitsPostage * 100, quantity: 1 },
        dnaPbfd: { cost: dnaPbfd.cost * 100, quantity: dnaPbfd.count },
        dnaApv: { cost: dnaApv.cost * 100, quantity: dnaApv.count },
        pbfdApv: { cost: pbfdApv.cost * 100, quantity: pbfdApv.count },
        dnaPbfdApv: { cost: dnaPbfdApv.cost * 100, quantity: dnaPbfdApv.count },
        //     ratites: { cost: ratites.cost * 100, quantity: ratites.count },
      }),
    })
    const session = await response.json()
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    })
    if (result.error) {
      setMessage({
        message:
          'Apologies. There is a technical issue. Please try again after sometime or speak to one of our advisors',
        style: { backgroundColor: 'red' },
      })
    }
  }
  return (
    <>
      {message && (
        <Alert style={message.style} dismissible onClose={() => setMessage()}>
          {message.message}
        </Alert>
      )}
      <Modal
        backdrop='static'
        keyboard={false}
        show={showPay}
        onHide={() => {
          setShowPay(false)
          setCallStripe(false)
        }}
      >
        <Modal.Header closeButton>
          <br />
          <Image
            style={{ width: '220px', height: '50px', cursor: 'pointer' }}
            src={'/images/logo-6.png'}
          />
          <br />
        </Modal.Header>
        <Modal.Body>
          <h3 align='center'>
            {' '}
            Summary of your Order{' '}
            <img
              src='/images/background/feather1.png'
              alt='Bird DNA Sexing'
              style={{ height: '100px' }}
            />
          </h3>
          <br /> <ProductDisplay handleClick={handleClick} />
          <h6 className='blink_me2'>
            {' '}
            if you have a discount code, please press Check out button and in
            the next screen click on Add Promotion Code{' '}
          </h6>
        </Modal.Body>
        <Modal.Footer>
          By clicking Checkout button above, you will be re-directed to our
          payment partner Stripe. You are agreeing Avegenics{' '}
          <a href='/TermsConditions'> Terms &amp; Conditions</a> and{' '}
          <a href='/Privacy'> Privacy Policies</a>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default StripePay
