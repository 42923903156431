import React, { useContext, useState, useEffect } from 'react'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { toast } from 'react-toastify'

import { useAuth } from '../config/AuthContext'
import SendMail from '../components/SendMail'

const GlobalContext = React.createContext()

export const useGlobal = () => {
  return useContext(GlobalContext)
}

const GlobalProvider = ({ children }) => {
  const Batches = firebase.firestore().collection('Batches')
  const { currentUser } = useAuth()
  const [buttonDisable, setButtonDisable] = useState(false)
  const PriceConfig = firebase.firestore().collection('config')
  const [config, setConfig] = useState({})
  const [payDetails, setPayDetails] = useState({})
  const dnaOnlyPrice = config && config.dnaOnlyPrice ? config.dnaOnlyPrice : 12
  const dnaDiscount = config && config.dnaDiscount ? config.dnaDiscount : 12
  const pbfdDiscount = config && config.pbfdDiscount ? config.pbfdDiscount : 15
  const apvDiscount = config && config.apvDiscount ? config.apvDiscount : 15
  const dnaApvDiscount =
    config && config.dnaApvDiscount ? config.dnaApvDiscount : 27
  const dnaPbfdDiscount =
    config && config.dnaPbfdDiscount ? config.dnaPbfdDiscount : 27
  const pbfdApvDiscount =
    config && config.pbfdApvDiscount ? config.pbfdApvDiscount : 30
  const dnaPbfdApvDiscount =
    config && config.dnaPbfdApvDiscount ? config.dnaPbfdApvDiscount : 40
  const pbfdOnlyPrice =
    config && config.pbfdOnlyPrice ? config.pbfdOnlyPrice : 18
  const apvOnlyPrice = config && config.apvOnlyPrice ? config.apvOnlyPrice : 18
  const dnaPbfdPrice = config && config.dnaPbfdPrice ? config.dnaPbfdPrice : 25
  const dnaApvPrice = config && config.dnaApvPrice ? config.dnaApvPrice : 25
  const pbfdApvPrice = config && config.pbfdApvPrice ? config.pbfdApvPrice : 30
  const dnaPbfdApvPrice =
    config && config.dnaPbfdApvPrice ? config.dnaPbfdApvPrice : 40
  const offerEndDate =
    config && config.offerEndDate ? config.offerEndDate : '202312312359'
  const offerStartDate =
    config && config.offerStartDate ? config.offerStartDate : '202001010000'
  const dnaSlab1Price =
    config && config.dnaSlab1Price ? config.dnaSlab1Price : 12
  const pbfdSlab1Price =
    config && config.pbfdSlab1Price ? config.pbfdSlab1Price : 15
  const apvSlab1Price =
    config && config.apvSlab1Price ? config.apvSlab1Price : 15
  const dnaApvSlab1Price =
    config && config.dnaApvSlab1Price ? config.dnaApvSlab1Price : 25
  const dnaPbfdSlab1Price =
    config && config.dnaPbfdSlab1Price ? config.dnaPbfdSlab1Price : 25
  const pbfdApvSlab1Price =
    config && config.pbfdApvSlab1Price ? config.pbfdApvSlab1Price : 30
  const dnaPbfdApvSlab1Price =
    config && config.dnaPbfdApvSlab1Price ? config.dnaPbfdApvSlab1Price : 40
  const slab1Target = config && config.slab1Target ? config.slab1Target : 9
  const dnaSlab2Price =
    config && config.dnaSlab2Price ? config.dnaSlab2Price : 12
  const pbfdSlab2Price =
    config && config.pbfdSlab2Price ? config.pbfdSlab2Price : 15
  const apvSlab2Price =
    config && config.apvSlab2Price ? config.apvSlab2Price : 15
  const dnaApvSlab2Price =
    config && config.dnaApvSlab2Price ? config.dnaApvSlab2Price : 25
  const dnaPbfdSlab2Price =
    config && config.dnaPbfdSlab2Price ? config.dnaPbfdSlab2Price : 25
  const pbfdApvSlab2Price =
    config && config.pbfdApvSlab2Price ? config.pbfdApvSlab2Price : 42
  const dnaPbfdApvSlab2Price =
    config && config.dnaPbfdApvSlab2Price ? config.dnaPbfdApvSlab2Price : 40
  const slab2Target = config && config.slab2Target ? config.slab2Target : 50

  useEffect(() => {
    getPriceConfig()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getPriceConfig = async () => {
    await PriceConfig.onSnapshot(
      (querySnapshot) => {
        let rcvdConfig = []
        querySnapshot.forEach((doc) => {
          rcvdConfig.push({ id: doc.id, ...doc.data() })
        })
        setConfig(rcvdConfig[0])
      },
      (err) => {
        return null
      }
    )
  }
  const forStripe = (batch) => {
    findOrderCost(batch, currentUser)
  }
  const findOrderCost = (batch, customer) => {
    let dnaOnlyCount = 0
    let pbfdOnlyCount = 0
    let apvOnlyCount = 0
    let dnaPbfdCount = 0
    let dnaApvCount = 0
    let pbfdApvCount = 0
    let dnaPbfdApvCount = 0
    const samples = batch.samples
    samples &&
      samples.length &&
      samples.forEach((sample) => {
        switch (sample.testType) {
          case 'DNA':
            dnaOnlyCount++
            break
          case 'PBFD':
            pbfdOnlyCount++
            break
          case 'APV':
            apvOnlyCount++
            break
          case 'DNA+PBFD':
            dnaPbfdCount++
            break
          case 'DNA+APV':
            dnaApvCount++
            break
          case 'PBFD+APV':
            pbfdApvCount++
            break
          case 'DNA+PBFD+APV':
            dnaPbfdApvCount++
            break
          default:
            dnaOnlyCount++
        }
      })

    let dnaCost =
      batch &&
      batch.batchName < offerEndDate &&
      batch.batchName > offerStartDate
        ? dnaDiscount * dnaOnlyCount
        : customer &&
          customer.customerDNAPrice &&
          customer.customerDNAPrice <= dnaOnlyPrice
        ? dnaOnlyCount * customer.customerDNAPrice
        : dnaOnlyCount > slab2Target
        ? dnaOnlyCount * dnaSlab2Price
        : dnaOnlyCount > slab1Target
        ? dnaOnlyCount * dnaSlab1Price
        : dnaOnlyCount * dnaOnlyPrice

    //   console.log(dnaCost, 'dnaCost')

    const pbfdCost =
      batch &&
      batch.batchName < offerEndDate &&
      batch.batchName > offerStartDate
        ? pbfdDiscount * pbfdOnlyCount
        : customer &&
          customer.customerPBFDPrice &&
          customer.customerPBFDPrice <= pbfdOnlyPrice
        ? pbfdOnlyCount * customer.customerPBFDPrice
        : pbfdOnlyCount > slab2Target
        ? pbfdOnlyCount * pbfdSlab2Price
        : pbfdOnlyCount > slab1Target
        ? pbfdOnlyCount * pbfdSlab1Price
        : pbfdOnlyCount * pbfdOnlyPrice

    const apvCost =
      batch &&
      batch.batchName < offerEndDate &&
      batch.batchName > offerStartDate
        ? apvDiscount * apvOnlyCount
        : customer &&
          customer.customerAPVPrice &&
          customer.customerAPVPrice <= apvOnlyPrice
        ? apvOnlyCount * customer.customerAPVPrice
        : apvOnlyCount > slab2Target
        ? apvOnlyCount * apvSlab2Price
        : apvOnlyCount > slab1Target
        ? apvOnlyCount * apvSlab1Price
        : apvOnlyCount * apvOnlyPrice
    const dnaPbfdCost =
      batch &&
      batch.batchName < offerEndDate &&
      batch.batchName > offerStartDate
        ? dnaPbfdDiscount * dnaPbfdCount
        : customer &&
          customer.customerDNAPBFDPrice &&
          customer.customerDNAPBFDPrice <= dnaPbfdPrice
        ? dnaPbfdCount * customer.customerDNAPBFDPrice
        : dnaPbfdCount > slab2Target
        ? dnaPbfdCount * dnaPbfdSlab2Price
        : dnaPbfdCount > slab1Target
        ? dnaPbfdCount * dnaPbfdSlab1Price
        : dnaPbfdCount * dnaPbfdPrice

    const dnaApvCost =
      batch &&
      batch.batchName < offerEndDate &&
      batch.batchName > offerStartDate
        ? dnaApvDiscount * dnaApvCount
        : customer &&
          customer.customerDNAAPVPrice &&
          customer.customerDNAAPVPrice <= dnaApvPrice
        ? dnaApvCount * customer.customerDNAAPVPrice
        : dnaApvCount > slab2Target
        ? dnaApvCount * dnaApvSlab2Price
        : dnaApvCount > slab1Target
        ? dnaApvCount * dnaApvSlab1Price
        : dnaApvCount * dnaApvPrice
    const pbfdApvCost =
      batch &&
      batch.batchName < offerEndDate &&
      batch.batchName > offerStartDate
        ? pbfdApvDiscount * pbfdApvCount
        : customer &&
          customer.customerPBFDAPVPrice &&
          customer.customerPBFDAPVPrice <= pbfdApvPrice
        ? pbfdApvCount * customer.customerPBFDAPVPrice
        : pbfdApvCount > slab2Target
        ? pbfdApvCount * pbfdApvSlab2Price
        : pbfdApvCount > slab1Target
        ? pbfdApvCount * pbfdApvSlab1Price
        : pbfdApvCount * pbfdApvPrice
    const dnaPbfdApvCost =
      batch &&
      batch.batchName < offerEndDate &&
      batch.batchName > offerStartDate
        ? dnaPbfdApvDiscount * dnaPbfdApvCount
        : customer &&
          customer.customerDNAPBFDAPVPrice &&
          customer.customerDNAPBFDAPVPrice <= dnaPbfdApvPrice
        ? dnaPbfdApvCount * customer.customerDNAPBFDAPVPrice
        : dnaPbfdApvCount > slab2Target
        ? dnaPbfdApvCount * dnaPbfdApvSlab2Price
        : dnaPbfdApvCount > slab1Target
        ? dnaPbfdApvCount * dnaPbfdApvSlab1Price
        : dnaPbfdApvCount * dnaPbfdApvPrice

    const kitsPostage = batch && batch.kitsNeeded ? 3 : 0

    const certsCount =
      batch && batch.certsNeeded
        ? dnaOnlyCount + dnaApvCount + dnaPbfdCount + dnaPbfdApvCount
        : 0
    const certsCost =
      batch &&
      batch.certsNeeded &&
      batch.samples &&
      batch.samples.length &&
      certsCount > 0
        ? 1 * certsCount
        : 0
    const certsPostage = certsCount > 0 ? config.privateCourier : 0
    const orderCost =
      Number((dnaCost * 10000) / 10000) +
      Number((pbfdCost * 10000) / 10000) +
      Number((apvCost * 10000) / 10000) +
      Number((dnaPbfdCost * 10000) / 10000) +
      Number((dnaApvCost * 10000) / 10000) +
      Number((pbfdApvCost * 10000) / 10000) +
      Number((dnaPbfdApvCost * 10000) / 10000) +
      Number((certsPostage * 10000) / 10000) +
      Number((certsCost * 10000) / 10000) +
      Number((kitsPostage * 10000) / 10000) +
      0
    setPayDetails((oldPayDetails) => {
      return {
        ...oldPayDetails,
        dna: { cost: dnaCost, count: dnaOnlyCount },
        pbfd: { cost: pbfdCost, count: pbfdOnlyCount },
        apv: { cost: apvCost, count: apvOnlyCount },
        dnaPbfd: { cost: dnaPbfdCost, count: dnaPbfdCount },
        dnaApv: { cost: dnaApvCost, count: dnaApvCount },
        pbfdApv: { cost: pbfdApvCost, count: pbfdApvCount },
        dnaPbfdApv: { cost: dnaPbfdApvCost, count: dnaPbfdApvCount },
        certsPostage,
        kitsPostage,
        certs: {
          cost: certsCost,
          count: batch && batch.samples && certsCount,
        },
        total: orderCost,
        order_id: batch.id,
        batch_no: batch.batchName,
      }
    })
    return orderCost
  }

  const submitOrderHandler = async (order, page) => {
    setButtonDisable(false)
    try {
      const resp = await Batches.add(order)
      resp && toast.success('Order created successfully')
      const samples = order.samples
      page !== 'Admin' &&
        (await SendMail(
          // only run this if page !== admin
          currentUser.displayName,
          order.custId,
          order,
          'Avigenics Order no',
          0,
          samples,
          samples.length,
          config
        ))
      await SendMail(
        order.customerName,
        order.custId,
        order,
        'You have new Order',
        1,
        samples,
        samples.length,
        config
      )
      order.kitsNeeded &&
        (order.kitsNeeded.type || order.kitsNeeded.total) &&
        (await SendMail(
          currentUser.displayName,
          order.custId,
          order,
          'Sample Kits Needed for Order:',
          6,
          samples,
          samples.length,
          config
        ))
    } catch (error) {
      console.log(error)
      toast('There was an Error')
    }
  }
  const updateOrder = async (order, page) => {
    setButtonDisable(false)
    try {
      await Batches.doc(order.id).set({ ...order })
      const samples = order.samples
      page !== 'Admin' &&
        SendMail(
          currentUser.displayName,
          order.custId,
          order,
          'Avigenics Order no',
          2,
          samples,
          samples.length,
          config
        )
      page !== 'Admin' &&
        SendMail(
          currentUser.displayName,
          order.custId,
          order,
          'Order Updated',
          3,
          samples,
          samples.length,
          config
        )
      toast.success('Order Updated successfully')
    } catch (error) {
      console.log(error)
      toast('There was an Error')
    }
  }

  const samplesUpdate = async (id, samples) => {
    await Batches.doc(id).update({ samples }, { merge: true })
  }

  const value = {
    submitOrderHandler,
    setButtonDisable,
    buttonDisable,
    updateOrder,
    samplesUpdate,
    findOrderCost,
    payDetails,
    setPayDetails,
    forStripe,
    config,
  }

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  )
}

export default GlobalProvider
