import { useEffect } from 'react'
import { useAdmin } from '../../config/AdminContext'
import ComponentToShow from './ComponentToShow'

const Deleted = () => {
  const { findFiltered, allBatches } = useAdmin()
  useEffect(() => {
    findFiltered('Delete')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allBatches])

  return <ComponentToShow prev={''} next={'In Progress'} />
}

export default Deleted
