import React, { useState, useEffect } from 'react'
import { Form, Button, Modal, Nav } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { useHistory } from 'react-router-dom'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { toast } from 'react-toastify'
import { isMobile } from 'react-device-detect'
import { useAuth } from '../config/AuthContext'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import usePlacesAutocomplete, {
  getGeocode,
  getZipCode,
} from 'use-places-autocomplete'
import useOnclickOutside from 'react-cool-onclickoutside'

function MyAccount(props) {
  const { currentUser } = useAuth()

  const [showUpdate, setShowUpdate] = useState(false)
  const [customer, setCustomer] = useState()
  const [custId, setCustId] = useState()

  const history = useHistory()
  const Customers = firebase.firestore().collection('Customers')
  const [dontAutoCorrect, setDontAutoCorrect] = useState(false)
  const [phone, setPhone] = useState(0)

  // const [landphone, setLandPhone] = useState(0)
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ['address'],
      componentRestrictions: { country: 'us' },
      /* Define search scope here */
    },
    debounce: 300,
  })

  useEffect(() => {
    if (!currentUser) {
      history.push('/Login')
    } else {
      getCustomer()
      checkCustomerDetails()
      window.scrollTo(0, 0)
    }
    if (customer) {
      setValue(customer && customer.address)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  ///////// GEO LOCATION //////////

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions()
  })

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value)
    // setCustomer({ ...customer, address: e.target.value })
  }

  const handleSelect =
    ({ description }) =>
    () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false)

      getGeocode({ address: description })
        // By default we use the "long_name" value from API response, you can tell the utility to use "short_name"
        // by setting the second parameter to "true"
        .then((results) => {
          //     console.log(results[0])
          const zipCode = getZipCode(results[0], false)
          setCustomer({ ...customer, address: description })
          setCustomer({ ...customer, pincode: zipCode })
        })
      clearSuggestions()

      // Get latitude and longitude via utility functions
    }

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion

      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      )
    })

  ///////// GEO LOCATION //////////

  const getCustomer = async () => {
    if (currentUser.email) {
      try {
        const resp = await Customers.where(
          'email',
          '==',
          currentUser.email
        ).get()
        resp.forEach((doc) => {
          setCustomer(doc.data())
          setCustId(doc.id)
        })
      } catch (error) {
        alert('Error' + error)
      }
    }
  }

  const checkCustomerDetails = () => {
    if (!customer || !customer.phone || !customer.pincode) {
      // setShowUpdate(true)
    }
  }

  const userUpdate = async (e) => {
    e.preventDefault()
    try {
      if (custId) {
        const resp = await Customers.doc(custId)
        const batch = firebase.firestore().batch()
        let mobile = 0
        if (phone === 0) {
          mobile = customer && customer.phone ? customer.phone : 0
        } else {
          mobile = phone
        }
        // if (landphone === 0) {
        //   landline = customer && customer.landphone ? customer.landphone : 0
        // } else {
        //   landline = landphone
        // }
        await batch.update(resp, {
          name: customer.name,
          email: customer.email,
          phone: mobile,
          // landphone: landline,
          address: value,
          pincode: customer.pincode,
        })
        await batch.commit()
      }

      closeUpdate()
      toast('Your details updated successfully.', { type: 'success' })
    } catch (error) {
      console.log(error)
      alert(error)
    }
  }
  const closeUpdate = () => {
    setShowUpdate(false)
  }
  const handleChangeName = (e) => {
    const fieldValue = e.target.value
    if (!dontAutoCorrect) {
      const correctedName = fieldValue.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      })
      setCustomer({ ...customer, name: correctedName })
    } else {
      setCustomer({ ...customer, name: fieldValue })
    }
  }
  const handleChange = (e) => {
    const fieldName = e.target.id
    const fieldValue = e.target.value
    if (fieldName === 'name' && dontAutoCorrect === false) {
      fieldValue.replace(/\w\S*/g, function (txt) {
        const fieldValueNew =
          txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
        setCustomer({ ...customer, [fieldName]: fieldValueNew })
      })
    } else {
      setCustomer({ ...customer, [fieldName]: fieldValue })
    }
  }

  return (
    <>
      {!isMobile && (
        <section className='page_breadcrumb'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12'>
                <ol className='page_bread'>
                  <li>
                    <a href='/'>Home</a>
                  </li>
                  <li className='current_page'>My Account</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
      )}
      {currentUser && customer && (
        <div className='container'>
          <div className='col-8'>
            <h2>Welcome to {customer.name}</h2>
            <h3>Account Details:</h3>

            <h5>
              Name: {customer.name}
              <br />
              E-Mail: {currentUser.email}
              <br />
              {customer.phone ? (
                <>
                  Phone: {customer.phone} <br />
                  Address: <br />
                  {value ? value : customer.address} <br />
                  {customer.pincode}
                </>
              ) : (
                <>
                  <Nav.Link onClick={() => setShowUpdate(true)}>
                    Modify <br />
                  </Nav.Link>
                  {customer.phone ? (
                    <></>
                  ) : (
                    <>
                      Your profile is incomplete. Please update your profile.{' '}
                      <Nav.Link
                        onClick={() => {
                          setValue(customer && customer.address, false)
                          setShowUpdate(true)
                        }}
                      >
                        Here
                      </Nav.Link>{' '}
                    </>
                  )}
                </>
              )}
              <Nav.Link
                onClick={() => {
                  setValue(customer && customer.address, false)
                  setShowUpdate(true)
                }}
              >
                Update your details
                <br />
              </Nav.Link>
              For orders, certificates etc, please go to{' '}
              <span
                style={{ color: 'blue', cursor: 'pointer' }}
                onClick={() => history.push('/OrderNow')}
              >
                Orders
              </span>{' '}
              Section
            </h5>
          </div>
        </div>
      )}
      {currentUser && customer && (
        <Modal
          backdrop='static'
          keyboard={false}
          show={showUpdate}
          onHide={() => {
            setShowUpdate(false)
            // history.replace(props.page ? props.page : '/MyAccount')
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update your details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={userUpdate}>
              <Form.Group>
                <Form.Text className='text-muted'>Full Name *</Form.Text>{' '}
                <Form.Control
                  id='name'
                  type='text'
                  value={customer.name}
                  placeholder='Enter Full Name'
                  onChange={handleChangeName}
                />
                <Form.Check
                  id='namechange'
                  type='checkbox'
                  checked={dontAutoCorrect}
                  label='Dont Auto Correct My Name'
                  onChange={() => setDontAutoCorrect(!dontAutoCorrect)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Text className='text-muted'>E-mail</Form.Text>{' '}
                <Form.Control
                  type='email'
                  id='email'
                  readOnly
                  placeholder='Email address'
                  value={customer.email}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId='formBasicPhone'>
                <PhoneInput
                  country={'us'}
                  value={customer && customer.phone}
                  onChange={(num) => setPhone(num)}
                  //          onChange={handleChange}
                />
              </Form.Group>
              {/* <Form.Group controlId='formBasicLandPhone'>
                <PhoneInput
                  country={'au'}
                  value={customer && customer.landphone}
                  onChange={(num) => setLandPhone(num)}
                  //          onChange={handleChange}
                />
              </Form.Group> */}
              <Form.Group>
                {/* <Form.Text className='text-muted'>Address</Form.Text>{' '} */}
                <div ref={ref}>
                  <Form.Control
                    as='textarea'
                    rows={5}
                    value={value}
                    onChange={handleInput}
                    disabled={!ready}
                    placeholder='Address'
                  />
                  {/* We can use the "status" to decide whether we should display the dropdown or not */}
                  {status === 'OK' && <ul>{renderSuggestions()}</ul>}
                </div>
              </Form.Group>
              <Form.Group>
                {/* <Form.Label>Confirm Password</Form.Label> */}
                <Form.Control
                  required
                  id='pincode'
                  placeholder='PostCode'
                  value={customer && customer.pincode}
                  onChange={(e) => {
                    setCustomer({
                      ...customer,
                      pincode: e.target.value,
                    })
                  }}
                />
              </Form.Group>
              {/* <Form.Group controlId='state'>
                <Form.Control
                  as='select'
                  defaultValue={customer.state}
                  onChange={handleChange}
                >
                  {' '}
                  <option default value={'SEL'}>
                    Select State
                  </option>
                  <option value={'ACT'}> </option>
                  <option value={'NSW'}>North South Wales</option>
                  <option value={'NT'}>NT</option>
                  <option value={'QL'}>QL</option>
                  <option value={'SA'}>SA</option>
                  <option value={'TAS'}>TAS</option>
                  <option value={'VIC'}> </option>
                  <option value={'WA'}>WA</option>
                </Form.Control>
              </Form.Group> */}
              <Button
                variant='secondary'
                onClick={() => {
                  setShowUpdate(false)
                  history.goBack()
                }}
              >
                Cancel
              </Button>{' '}
              <Button variant='primary' type='submit'>
                Update
              </Button>
            </Form>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      )}
    </>
  )
}

export default MyAccount
