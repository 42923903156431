/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import { useAuth } from '../config/AuthContext'
import { useGlobal } from './GlobalContext'
import { loadStripe } from '@stripe/stripe-js'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { toast } from 'react-toastify'
import SendMail from '../components/SendMail'
const OrderContext = React.createContext()

export const useOrder = () => {
  return useContext(OrderContext)
}

const OrderProvider = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const Batches = firebase.firestore().collection('Batches')
  const Customers = firebase.firestore().collection('Customers')
  const Species = firebase.firestore().collection('Species')
  // const Ratites = firebase.firestore().collection('Ratites')
  const [allBatches, setAllBatches] = useState()
  const { currentUser } = useAuth()
  const [customer, setCustomer] = useState({})
  const [callStripe, setCallStripe] = useState(false)
  const [allSpecies, setAllSpecies] = useState()
  // const [allRatites, setAllRatites] = useState()
  const PriceConfig = firebase.firestore().collection('config')
  const [config, setConfig] = useState()
  const [payDetails, setPayDetails] = useState()
  const { showTotalCost } = useGlobal()

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

  useEffect(() => {
    getAllOrders()
    getCustomer()
    getSpecies()
    // getRatites()
    getConfig()
  }, [])
  const getAllOrders = async () => {
    if (currentUser && currentUser.email) {
      await Batches.where('custId', '==', currentUser.email).onSnapshot(
        (querySnapshot) => {
          let rcvdBatches = []
          querySnapshot.forEach((doc) => {
            rcvdBatches.push({ id: doc.id, ...doc.data() })
          })
          setAllBatches(rcvdBatches)
        },
        (err) => {
          return null
        }
      )
    }
    setLoading(false)
  }

  const getConfig = async () => {
    await PriceConfig.onSnapshot(
      (querySnapshot) => {
        let rcvdConfig = []
        querySnapshot.forEach((doc) => {
          rcvdConfig.push({ id: doc.id, ...doc.data() })
        })
        setConfig(rcvdConfig[0])
      },
      (err) => {
        return null
      }
    )
  }

  const updateConfig = async (id, data) => {
    await PriceConfig.doc('Config').update({ ...data }, { merge: true })
    alert('Prices updated!')
  }
  const forStripe = (batch) => {
    const totalCost = showTotalCost(batch, customer)

    setPayDetails({
      dna: totalCost[0],
      pbfd: totalCost[1],
      apv: totalCost[2],
      kits: totalCost[3],
      postage: totalCost[4],
      cert1: totalCost[5],
      cert2: totalCost[6],
      quantity: totalCost[7],
      batch_no: batch.id,
      order_id: batch.batchName,
    })
  }

  const getCustomer = async () => {
    if (currentUser && currentUser.email) {
      await Customers.where('email', '==', currentUser.email).onSnapshot(
        (querySnapshot) => {
          let rcvdCustomer = []
          querySnapshot.forEach((doc) => {
            rcvdCustomer.push({ id: doc.id, ...doc.data() })
          })
          setCustomer(rcvdCustomer[0])
        },
        (err) => {
          return null
        }
      )
    }
  }
  const getSpecies = async () => {
    const docRef = Species.doc('Species')
    await docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          setAllSpecies(doc.data())
        } else {
          // doc.data() will be undefined in this case
          console.log('Sorry Cant get Species List!')
        }
      })
      .catch((error) => {
        console.log('Error getting document:', error)
      })
  }

  // const getRatites = async () => {
  //   const docRef = Ratites.doc('Ratites')
  //   await docRef
  //     .get()
  //     .then((doc) => {
  //       if (doc.exists) {
  //         setAllRatites(doc.data())
  //       } else {
  //         // doc.data() will be undefined in this case
  //         console.log('Sorry Cant get Ratites List!')
  //       }
  //     })
  //     .catch((error) => {
  //       console.log('Error getting document:', error)
  //     })
  // }

  const deleteBatch = async (batchId, batchName) => {
    if (window.confirm(`Are you sure, you want to delete this order?`)) {
      try {
        await Batches.doc(batchId).delete()
        SendMail(
          currentUser.displayName,
          currentUser.email,
          batchName,
          'Avigenics Order no',
          4,
          []
          // samples.length
        )
        SendMail(
          currentUser.displayName,
          currentUser.email,
          batchName,
          'Deleted Order no',
          5,
          []
          // samples.length
        )
        toast('The selected Order is deleted!')
      } catch (error) {
        toast(
          'There was an error! Please try again or contact our support staff'
        )
      }
    }
  }

  const handlePostOrder = async (id, courierCompany, courierRef) => {
    await Batches.doc(id).update(
      { courierCompany, courierRef, status: 'Posted' },
      { merge: true }
    )
  }
  const updateBalance = async (price, tests) => {
    await Customers.doc(customer.id).update(
      { balance: price, DNATests: tests, bulkPaid: 'Not Paid' },
      { merge: true }
    )
  }
  const updateSpecies = async (species) => {
    await Species.doc('Species').update({ ...species }, { merge: true })
    alert('Species updated!')
  }
  const bulkPay = async (customer) => {
    const stripe = await stripePromise
    const response = await fetch('/api/create-checkout-session/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        bulkTest: customer * 100,
        quantity: 1,
        email: currentUser.email,
        username: currentUser.displayName,
      }),
    })

    const session = await response.json()
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    })
    if (result.error) {
      alert(
        'Apologies. There is a technical issue. Please try again after sometime or speak to one of our advisors'
      )
    }
  }

  const value = {
    allBatches,
    updateSpecies,
    setAllBatches,
    allSpecies,
    setAllSpecies,
    getAllOrders,
    handlePostOrder,
    deleteBatch,
    customer,
    currentUser,
    getCustomer,
    forStripe,
    payDetails,
    callStripe,
    setCallStripe,
    updateBalance,
    bulkPay,

    config,
    setConfig,
    getConfig,
    updateConfig,
  }

  return (
    <OrderContext.Provider value={value}>
      {!loading && children}
    </OrderContext.Provider>
  )
}

export default OrderProvider
