import { useEffect, useRef, useState } from 'react'
import {
  Form,
  Button,
  Alert,
  ListGroup,
  Row,
  Col,
  Image,
  Modal,
  Container,
  Spinner,
} from 'react-bootstrap'
import moment from 'moment'
import * as Icon from 'react-bootstrap-icons'
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import { useGlobal } from '../config/GlobalContext'
import firebase from 'firebase/app'
import { useOrder } from '../config/OrderContext'
const NewOrder = (props) => {
  const [samples, setSamples] = useState([])
  const [kitsNeeded, setKitsNeeded] = useState(false)
  const [ringNo, setRingNo] = useState('')
  const [selectedSpecies, setSelectedSpecies] = useState([])
  const [dnaChecked, setDnaChecked] = useState(props.clickedButton === 'dna')
  const [pbfdChecked, setPbfdChecked] = useState(props.clickedButton === 'pbfd')
  const [apvChecked, setApvChecked] = useState(props.clickedButton === 'apv')
  const [physicalCerts, setPhysicalCerts] = useState(false)
  const [showAddressAlert, setShowAddressAlert] = useState(true)
  const [message, setMessage] = useState({})
  const [sampleType, setSampleType] = useState('')
  const [order, setOrder] = useState({})
  const { allSpecies } = useOrder()
  const [imgLoaded, setImgLoaded] = useState(false)
  const [config, setConfig] = useState({})
  const [ownerName, setOwnerName] = useState('')

  const [showBiggerImage, setShowBiggerImage] = useState({
    type: 'A5',
    show: false,
  })

  let { customer } = useOrder()
  if (props.page === 'Admin') {
    customer = props.customer
  }

  const AllSpecies = []
  //  const AllRatites = []

  allSpecies && AllSpecies.push(allSpecies.AllSpecies)
  //  allRatites && AllRatites.push(allRatites.AllRatites)
  const { findOrderCost, submitOrderHandler, setPayDetails } = useGlobal()
  const [hideSubmit, setHideSubmit] = useState(true)
  let customerID = props.custId ? props.custId : 'new-order@avigenicsusa.com'
  const customerName =
    props.customer && props.customer.name
      ? props.customer.name
      : customer.name
      ? customer.name
      : 'Customer Name need to be updated'
  const ref = useRef()
  const PriceConfig = firebase.firestore().collection('config')

  useEffect(() => {
    setTimeout(() => {
      setImgLoaded(true)
    }, 1500)

    handleNewBatch()
    getPriceConfig()
    setOwnerName(
      props.customer && props.customer.name
        ? props.customer.name
        : customer && customer.name
        ? customer.name
        : customerName
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const getPriceConfig = async () => {
    await PriceConfig.onSnapshot(
      (querySnapshot) => {
        let rcvdConfig = []
        querySnapshot.forEach((doc) => {
          rcvdConfig.push({ id: doc.id, ...doc.data() })
        })
        setConfig(rcvdConfig[0])
      },
      (err) => {
        return null
      }
    )
  }
  const addSampleHandler = async () => {
    setHideSubmit(false)
    if (
      (!ringNo || !selectedSpecies || !selectedSpecies.length) &&
      props.page === 'Order'
    ) {
      setMessage({
        message: 'Ring Id and species names are mandatory',
        style: { backgroundColor: '#FF0029' },
      })
    } else {
      let testType = ''
      switch (pbfdChecked || dnaChecked || apvChecked) {
        case pbfdChecked && !dnaChecked && !apvChecked:
          testType = 'PBFD'
          break
        case apvChecked && !dnaChecked && !pbfdChecked:
          testType = 'APV'
          break
        case pbfdChecked && dnaChecked && !apvChecked:
          testType = 'DNA+PBFD'
          break
        case apvChecked && dnaChecked && !pbfdChecked:
          testType = 'DNA+APV'
          break
        case pbfdChecked && apvChecked && !dnaChecked:
          testType = 'PBFD+APV'
          break
        case pbfdChecked && dnaChecked && apvChecked:
          testType = 'DNA+PBFD+APV'
          break
        // case props && props.clickedButton === 'RATITES':
        //   testType = 'RATITES'
        //   break
        default:
          testType = 'DNA'
      }
      // if (
      //   Ratites.includes(
      selectedSpecies && selectedSpecies.length > 0 && selectedSpecies.trim()
      //   )
      // ) {
      //   alert(`${selectedSpecies} samples need to be added in Ratites section`)
      // } else {
      samples.push({ ringNo, selectedSpecies, testType, sampleType, ownerName })
      const orderCost = await findOrderCost({ ...order, samples }, customer)
      setRingNo('')
      setOrder((oldOrder) => ({
        ...oldOrder,
        samples,
        orderCost,
      }))
      //   }
    }
  }

  const handleNewBatch = () => {
    setPayDetails({})
    const batchName = moment(new Date())
      .utcOffset('0:00')
      .format('YYYYMMDDHHmm')
    setOrder((oldOrder) => ({
      ...oldOrder,
      batchName,
      status: 'In Progress',
      payStatus: 'Not Paid',
      custId: customerID,
      customerName: customer && customer.name ? customer.name : customerName,
      samples: [],
      orderType: props.page === 'Admin' ? 'Manual' : 'Online',
      ownerName,
      orderCost: 0,
    }))
  }
  const handleKitsType = async (e) => {
    const orderCost = await findOrderCost(
      { ...order, samples, kitsNeeded: { type: e.target.value } },
      customer
    )

    setOrder((oldOrder) => ({
      ...oldOrder,
      kitsNeeded: { type: e.target.value },
      orderCost,
    }))
  }
  const handleKitsNeeded = (e) => {
    if (!e.target.checked) {
      const orderCost = findOrderCost(
        { ...order, samples, kitsNeeded: null },
        customer
      )
      order && order.kitsNeeded && delete order.kitsNeeded
      setOrder((oldOrder) => ({
        ...oldOrder,
        orderCost,
      }))
    } else {
      const orderCost = findOrderCost(
        { ...order, samples, kitsNeeded: { type: 'Feather Samples' } },
        customer
      )
      setOrder((oldOrder) => ({
        ...oldOrder,
        kitsNeeded: { type: 'Feather Samples' },
        orderCost,
      }))
    }
  }

  const handleCerts = (e) => {
    if (!e.target.checked) {
      const orderCost = findOrderCost(
        { ...order, samples, certsNeeded: null },
        customer
      )
      order && order.certsNeeded && delete order.certsNeeded
      setOrder((oldOrder) => ({
        ...oldOrder,
        orderCost,
      }))
    } else {
      const orderCost = findOrderCost(
        {
          ...order,
          samples,
          certsNeeded: { type: 'Printed Certificates' },
        },
        customer
      )
      setOrder((oldOrder) => ({
        ...oldOrder,
        certsNeeded: { type: 'Printed Certificates' },
        orderCost,
      }))
    }
  }
  const handleRemoveSamples = (index) => {
    const newSamples = samples.filter((_, item) => item !== index)
    newSamples.length === 0 && setHideSubmit(true)
    setSamples((oldSamples) => newSamples)
    const orderCost = findOrderCost({ ...order, samples: newSamples }, customer)
    setOrder((oldOrder) => ({
      ...oldOrder,
      samples: newSamples,
      orderCost,
    }))
  }

  return (
    <>
      {' '}
      {message && message.message && (
        <Alert style={message.style} dismissible onClose={() => setMessage()}>
          {message.message}
        </Alert>
      )}{' '}
      <Container fluid>
        {!imgLoaded && !AllSpecies && AllSpecies.length < 0 && (
          <Spinner
            animation='border'
            variant='primary'
            size='100px'
            style={{
              marginTop: '100px',
              width: '2rem',
              height: '2rem',
              marginLeft: '50%',
            }}
          />
        )}
      </Container>
      <Form.Text>
        <strong>
          Order No: {order && order.batchName} {'     '}Total Samples:{' '}
          {order && order.samples ? order.samples.length : 0} {'     '}Total
          cost:{' '}
          <span style={{ color: 'red', cursor: 'pointer' }}>
            $
            {order &&
              order.orderCost &&
              Math.round(order.orderCost * 100) / 100}
          </span>
        </strong>{' '}
      </Form.Text>{' '}
      <Form.Check
        id='kits'
        type='switch'
        label={`Sample Collection kit  ($${
          config && config.speedPost
        } postage per order)`}
        checked={kitsNeeded}
        onChange={(e) => {
          handleKitsNeeded(e)
          setKitsNeeded(!kitsNeeded)
        }}
      />
      {(kitsNeeded || physicalCerts) &&
        props.page !== 'Admin' &&
        showAddressAlert && (
          <Alert
            variant='primary'
            dismissible
            onClose={() => setShowAddressAlert(false)}
          >
            <Form.Text muted>
              <span>
                * Please make sure you have entered your postal address. You can
                do it in MyAccount section after submitting this order.
                <br />
                Certificates are available for DNA testing only.
              </span>
            </Form.Text>
          </Alert>
        )}
      {kitsNeeded && (
        <Form.Group>
          <Form.Check
            inline
            type={'radio'}
            name={'kitsGroup'}
            checked={
              order.kitsNeeded && order.kitsNeeded.type === 'Feather Samples'
            }
            value={'Feather Samples'}
            label={'Feather Samples'}
            onChange={handleKitsType}
          />
          <Form.Check
            inline
            type={'radio'}
            name={'kitsGroup'}
            checked={
              order.kitsNeeded && order.kitsNeeded.type === 'Blood Samples'
            }
            value={'Blood Samples'}
            label={'Blood Samples'}
            onChange={handleKitsType}
          />{' '}
          <Form.Check
            inline
            type={'radio'}
            name={'kitsGroup'}
            checked={order.kitsNeeded && order.kitsNeeded.type === 'Both'}
            value={'Both'}
            label={'Both'}
            onChange={handleKitsType}
          />
        </Form.Group>
      )}
      <Form.Check
        id='certs'
        type='switch'
        //    disabled={!dnaChecked}
        label={`Printed Certificates ($${
          config && config.certsPrice
        } each and $${config && config.speedPost}  postage per order)`}
        checked={physicalCerts}
        onChange={(e) => {
          handleCerts(e)
          setPhysicalCerts(!physicalCerts)
        }}
      />
      {/* {physicalCerts && (
        <Form.Group>
          <Form.Check
            inline
            type={'radio'}
            name={'certsGroup'}
            checked={
              order.certsNeeded &&
              order.certsNeeded.type === 'Printed Certificates'
            }
            value={'Printed Certificates'}
            label={'Printed $2 each'}
            onChange={(e) => handleCerts(e, 'cert1', 'cert2')}
          />{' '}
          <Image
            style={{ width: '30px', height: '25px', cursor: 'pointer' }}
            onClick={() => {
              setShowBiggerImage({ type: 'A5', show: true })
            }}
            src={'/images/sampleA5.png'}
          />{' '}
          <Form.Check
            inline
            type={'radio'}
            name={'certsGroup'}
            checked={
              order.certsNeeded &&
              order.certsNeeded.type === 'CreditCard Certificates'
            }
            value={'CreditCard Certificates'}
            label={'CreditCard $2 each'}
            onChange={(e) => handleCerts(e, 'cert2', 'cert1')}
          />{' '}
          <Image
            style={{ width: '30px', height: '25px', cursor: 'pointer' }}
            onClick={() => setShowBiggerImage({ type: 'CC', show: true })}
            src={'/images/sampleCC.png'}
          />
        </Form.Group>
      )} */}
      <br />
      <Form.Group
        style={{
          borderColor: 'gray',
          borderWidth: '2px',
          borderStyle: 'dotted',
          borderRadius: '5px',
          padding: '10px',
        }}
      >
        <Row>
          <Col sm='4'>
            <Form.Check
              key='dna'
              type='checkbox'
              label='DNA Sexing'
              checked={dnaChecked}
              onChange={(e) => {
                setDnaChecked(!dnaChecked)
              }}
            />
          </Col>{' '}
          <Col sm='3'>
            <Form.Check
              key='pbfd'
              type='checkbox'
              label='PBFD'
              checked={pbfdChecked}
              onChange={(e) => {
                setPbfdChecked(!pbfdChecked)
              }}
            />
          </Col>{' '}
          <Col sm='3'>
            <Form.Check
              key='apv'
              type='checkbox'
              label='APV'
              checked={apvChecked}
              onChange={(e) => {
                setApvChecked(!apvChecked)
              }}
            />
          </Col>{' '}
        </Row>{' '}
        <Form.Text muted>Owner Name:</Form.Text>
        <Form.Control
          value={ownerName}
          required
          style={{
            borderColor: 'blue',
          }}
          onChange={(e) => setOwnerName(e.target.value)}
          placeholder='Owner Name'
        />{' '}
        <Form.Text muted>Sample Type:</Form.Text>
        <Form.Control
          style={{ minWidth: '100px' }}
          as='select'
          defaultValue={sampleType}
          onChange={(e) => {
            setSampleType(e.target.value)
          }}
          placeholder='Select here'
        >
          <option key={'0'} value={''}></option>
          <option key={'1'} value={'Feather'}>
            Feather Samples
          </option>
          <option key={'2'} value={'Blood'}>
            Blood Samples
          </option>
          <option key={'3'} value={'Feather & Blood'}>
            Both
          </option>
        </Form.Control>
        <Form.Text muted>* Ring Id:</Form.Text>
        <Form.Control
          value={ringNo}
          required
          style={{
            borderColor: 'blue',
          }}
          onChange={(e) => setRingNo(e.target.value.toUpperCase())}
          placeholder='Ring No or Bird Name'
        />
        <Form.Text muted>* Species:</Form.Text>
        <Typeahead
          style={{ all: 'unset' }}
          options={AllSpecies && AllSpecies.length > 0 && AllSpecies[0]}
          placeholder='Species (e.g. Blackbird)'
          ref={ref}
          id='basic-typeahead-single'
          value={selectedSpecies ? selectedSpecies : ''}
          onInputChange={(e) => {
            setSelectedSpecies(
              e.replace(/\w\S*/g, function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
              })
            )
          }}
          onChange={(e) => {
            setSelectedSpecies(e[0])
          }}
        />
        <Form.Text muted>
          <span style={{ color: 'red' }}>
            If you cant find the species in the list, please type full species
            name.
          </span>
        </Form.Text>
        <div align='right'>
          {dnaChecked || pbfdChecked || apvChecked ? (
            <Button
              style={{
                padding: '1px',
                borderRadius: '30px',
                // margin: '1px',
                width: '60px',
              }}
              disabled={false}
              onClick={() => {
                addSampleHandler()
              }}
            >
              Add
            </Button>
          ) : (
            <span style={{ color: 'red' }}>
              You need to purchase more tests before adding another sample
            </span>
          )}
        </div>
      </Form.Group>
      <br />
      <ListGroup size='sm'>
        {samples &&
          samples.length > 0 &&
          samples.map((sample, index) => (
            <ListGroup.Item key={index}>
              <Form.Text muted>Test: {sample.testType}</Form.Text>
              {index + 1} <>&nbsp; {sample.ringNo}</>
              {/* )} */}
              <> &nbsp; {sample.selectedSpecies} &nbsp;&nbsp;&nbsp;</>
              <Icon.TrashFill
                fill='red'
                onClick={() => {
                  if (
                    window.confirm('Are you sure, you want to delete this?')
                  ) {
                    handleRemoveSamples(index)
                  }
                }}
              />
              &nbsp;
              <br />
            </ListGroup.Item>
          ))}
      </ListGroup>{' '}
      <div align='right'>
        {((dnaChecked || pbfdChecked || apvChecked) && !hideSubmit) ||
        props.page === 'Admin' ? (
          <>
            <Button
              style={{
                padding: '1px',
                borderRadius: '30px',
                width: '60px',
              }}
              disabled={
                order.samples &&
                order.samples.length === 0 &&
                props.page !== 'Admin'
              }
              onClick={async () => {
                if (!kitsNeeded) {
                  if (
                    window.confirm(
                      'You have not ordered sample kits.\n Are you sure you want use your own bags?'
                    )
                  ) {
                    if (order.samples.length > 0) {
                      await submitOrderHandler(...order, props.page)

                      props.page !== 'Admin'
                        ? props.setShowNewOrder(false)
                        : props.setShowModal({ name: '', show: false })
                    } else {
                      alert('Empty Order')
                    }
                  }
                } else {
                  if (order.samples.length > 0) {
                    await submitOrderHandler(...order, props.page)
                    props.page !== 'Admin'
                      ? props.setShowNewOrder(false)
                      : props.setShowModal({ name: '', show: false })
                  } else {
                    alert('Empty Order')
                  }
                }
              }}
            >
              Submit
            </Button>
          </>
        ) : (
          <span style={{ color: 'red' }}>
            You need to add at least one sample.
          </span>
        )}
      </div>{' '}
      <Modal
        show={showBiggerImage.show}
        onHide={() => setShowBiggerImage({ show: false })}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          {showBiggerImage.type === 'A5' ? (
            <Image
              onClick={() => setShowBiggerImage({ show: false })}
              src={'/images/sampleA5.png'}
            />
          ) : (
            <Image
              style={{
                padding: '1px',
                borderRadius: '10px',
                margin: '1px',
                width: '260px',
              }}
              onClick={() => setShowBiggerImage({ show: false })}
              src={'/images/sampleCC.png'}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default NewOrder
