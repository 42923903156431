const ShowTips = () => {
  return (
    <div
      className='content-column col-lg-6 col-md-12 col-sm-12'
      style={{ margin: '0px' }}
    >
      <div className='inner-column'>
        <div className='sec-title'>
          <h5>Self sealing bags</h5>
        </div>
        <div className='bold-text'>
          <h6 style={{ backgroundColor: 'yellow' }}>
            Please use own self-sealing bags. Alternatively, You can order them
            by selecting "Sample collection Kit" during new order or by clicking
            on Modify for existing Orders. ($3 postage charges apply per order).
            Any difficulties, please contact our office.
          </h6>{' '}
        </div>
        <div className='sec-title'>
          <h5>Add/Delete samples</h5>
        </div>
        <h6 style={{ backgroundColor: 'white' }}>
          You can add more samples to an order or delete samples from an order
          until you pay. Also you can modify ring no or species names.
        </h6>{' '}
        <br />
        <div className='sec-title'>
          <h5>Modify ring no or species names</h5>
        </div>
        <h6>
          Once the order is paid, you can only modify ring no (bird name) or
          species name until you post it.
        </h6>
        <h6 style={{ backgroundColor: 'yellow' }}>
          Once the order is posted, you cannot modify anything. For any typo
          errors to be corrected, please contact our office.
        </h6>
      </div>
    </div>
  )
}

export default ShowTips
