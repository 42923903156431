const CreateOnline = () => {
  return (
    <>
      <h6>
        {' '}
        Before creating order, you need to <a href='/Login'>login</a>.<br /> If
        you dont have a login, please <a href='/Register'>register</a>. <br />
        Once logged on, please navigate to <a href='/OrderNow'>
          Order Now
        </a>{' '}
        <br />
        page and follow the steps below:
      </h6>
      {/* <!-- Mission Section --> */}
      <section className='mission-section clearfix'>
        <div className='auto-container'>
          <div className='row clearfix'>
            {/* <!-- Image Column --> */}
            <div className='image-column col-lg-6 col-md-12 col-sm-12'>
              <div className='inner-column'>
                <div
                  className='image wow fadeInLeft'
                  data-wow-delay='0ms'
                  data-wow-duration='1500ms'
                >
                  <img
                    src='images/resource/click_on_button.png'
                    alt=''
                    style={{
                      borderRadius: '15px',
                      boxShadow: '7px 5px 5px 0px rgba(0,0,0,0.5)',
                    }}
                  />
                </div>
              </div>
            </div>

            {/* <!-- Content Column --> */}
            <div className='content-column col-lg-6 col-md-12 col-sm-12'>
              <div className='inner-column'>
                {/* <!-- Sec Title --> */}
                <div className='sec-title'>
                  {/* <div className='title'>AVIGENICS</div> */}
                  <h3>Select test</h3>
                </div>
                <div className='bold-text'>
                  Click on DNA, PBFD or APV button as required. If you
                  need more than one test, click any button and you can add more
                  tests in the next screen{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='mission-section clearfix'>
        <div className='auto-container'>
          <div className='row clearfix'>
            {/* <!-- Image Column --> */}
            <div className='image-column col-lg-6 col-md-12 col-sm-12'>
              <div className='inner-column'>
                <div
                  className='image wow fadeInLeft'
                  data-wow-delay='0ms'
                  data-wow-duration='1500ms'
                >
                  <img
                    src='images/resource/new_order.png'
                    alt=''
                    style={{
                      borderRadius: '15px',
                      boxShadow: '7px 5px 5px 0px rgba(0,0,0,0.5)',
                    }}
                  />{' '}
                </div>
              </div>
            </div>
            {/* <!-- Content Column --> */}
            <div className='content-column col-lg-6 col-md-12 col-sm-12'>
              <div className='inner-column'>
                {/* <!-- Sec Title --> */}
                <div className='sec-title'>
                  {/* <div className='title'>AVIGENICS</div> */}
                  <h3>Select all required tests </h3>
                </div>
                <div className='bold-text'>
                  Select all the required tests for this bird and enter the bird
                  details. Also you can order sample collection kits and printed
                  certificates.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='mission-section clearfix'>
        <div className='auto-container'>
          <div className='row clearfix'>
            {/* <!-- Image Column --> */}
            <div className='image-column col-lg-6 col-md-12 col-sm-12'>
              <div className='inner-column'>
                <div
                  className='image wow fadeInLeft'
                  data-wow-delay='0ms'
                  data-wow-duration='1500ms'
                >
                  <img
                    src='images/resource/start_searching_species.png'
                    alt=''
                    style={{
                      borderRadius: '15px',
                      boxShadow: '7px 5px 5px 0px rgba(0,0,0,0.5)',
                    }}
                  />{' '}
                </div>
              </div>
            </div>
            {/* <!-- Content Column --> */}
            <div className='content-column col-lg-6 col-md-12 col-sm-12'>
              <div className='inner-column'>
                {/* <!-- Sec Title --> */}
                <div className='sec-title'>
                  {/* <div className='title'>AVIGENICS</div> */}
                  <h3>Add sample details </h3>
                </div>
                <div className='bold-text'>
                  Select sample type (Blood/Feather/Both). We recommend both
                  blood and feather samples for PBFD and APV tests. Enter Ring
                  No (or bird name) and start typing species name. If found,
                  select it from the list. <br />{' '}
                  <p style={{ backgroundColor: 'yellow' }}>
                    If you cannot find the species name in the list, please type
                    full species name.
                  </p>{' '}
                  Click on Add. Repeat same process for all the birds. Once all
                  the birds are added, click on Submit button. You can add more
                  by selecting Modify Order button after submitting the order as
                  well.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Services Section Three --> */}
    </>
  )
}

export default CreateOnline
