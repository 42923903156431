import { useEffect, useState } from 'react'
import { Table, Nav, Button, Row, Col, Form } from 'react-bootstrap'

import { useAdmin } from '../../config/AdminContext'
import AdminModals from './AdminModals'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'

const AllCustomers = () => {
  const [showModal, setShowModal] = useState({ name: '', show: false })

  const {
    filteredCustomers,
    setFilteredCustomers,
    allCustomers,
    //   getCustomers,
    allBatches,
    setFilteredBatches,
    getCustomer,
    setSelectedCustomer,
  } = useAdmin()

  const curr = new Date()
  curr.setDate(curr.getDate())
  const date = curr.toISOString().split('T')[0]
  useEffect(() => {
    return () => {
      //     getCustomers()
      setFilteredBatches(allBatches)
      setFilteredCustomers(allCustomers)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sortByCreatedDateAsc = () => {
    const newFilteredCustomers = filteredCustomers.sort((a, b) => {
      if (a.created !== undefined || b.created !== undefined)
        return new Date(a.created) - new Date(b.created)
      else return 0
    })
    setFilteredCustomers(
      newFilteredCustomers.filter(
        (Customers) => Customers.created !== undefined
      )
    )
  }

  const sortByCreatedDateDesc = () => {
    const newFilteredCustomers = filteredCustomers.sort((a, b) => {
      if (a.created !== undefined || b.created !== undefined)
        return new Date(b.created) - new Date(a.created)
      else return 0
    })
    setFilteredBatches(
      newFilteredCustomers.filter(
        (Customers) => Customers.created !== undefined
      )
    )
  }

  const exportToExcel = () => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    const ws = XLSX.utils.json_to_sheet(allCustomers.flat())
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, 'Customer Backup On ' + date + fileExtension)
  }
  return (
    <div>
      <Row className='justify-content-center'>
        <Col align='center' sm={2}>
          <Button
            style={{
              padding: '4px',
              borderRadius: '30px',
              margin: '3px',
              width: '140px',
            }}
            onClick={async () => {
              await setSelectedCustomer({})
              await setShowModal({ name: 'Customer', show: true })
            }}
          >
            Create Customer
          </Button>
        </Col>
        <Col align='center' sm={2}>
          {' '}
          <Button
            style={{
              padding: '4px',
              borderRadius: '30px',
              margin: '3px',
              width: '140px',
            }}
            onClick={() => exportToExcel()}
          >
            Export to Excel
          </Button>
        </Col>
        <Col sm={2}>
          <Form.Control
            style={{ maxWidth: '300px' }}
            className='mb-2 mt-1'
            type='text'
            placeholder='Customer Name'
            onChange={(e) => {
              setFilteredCustomers(
                allCustomers.filter(
                  (customer) =>
                    customer.name &&
                    customer.name
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase())
                )
              )
            }}
          />
        </Col>
        <Col sm={2}>
          <Form.Control
            style={{ maxWidth: '300px' }}
            className='mb-2 mt-1'
            type='text'
            placeholder='Customer Email'
            onChange={(e) => {
              setFilteredCustomers(
                allCustomers &&
                  allCustomers.length > 0 &&
                  allCustomers.filter(
                    (customer) =>
                      customer.email &&
                      customer.email
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                  )
              )
            }}
          />
        </Col>
        <Col sm={2}>
          <Form.Control
            style={{ maxWidth: '300px' }}
            className='mb-2 mt-1'
            type='number'
            placeholder='Phone Number'
            onChange={(e) => {
              setFilteredCustomers(
                allCustomers.filter(
                  (customer) =>
                    customer.phone && customer.phone.includes(e.target.value)
                )
              )
            }}
          />
        </Col>
      </Row>
      Total Customers: {allCustomers && allCustomers.length}
      <Table responsive size='sm'>
        <thead>
          <tr>
            <th>
              Customer Name{' '}
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => sortByCreatedDateAsc()}
              >
                ↑
              </span>{' '}
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => sortByCreatedDateDesc()}
              >
                ↓
              </span>{' '}
            </th>
            <th>E-Mail</th>
            <th>Phone No</th>
            <th>PostCode</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {filteredCustomers &&
            filteredCustomers.map((customer) => (
              <tr key={customer.id}>
                <td>
                  <Nav.Link
                    onClick={() => {
                      getCustomer(customer.email)
                      setShowModal({ name: 'Customer', show: true })
                    }}
                  >
                    {customer.name}
                  </Nav.Link>
                </td>
                <td>{customer.email}</td>
                <td>{customer.phone}</td>
                <td>{customer.pincode}</td>
                {/* <td>{countOrders(customer.email)}</td> */}
                <td>
                  <Button
                    style={{
                      padding: '4px',
                      borderRadius: '30px',
                      margin: '3px',
                      width: '100px',
                    }}
                    onClick={async () => {
                      await setSelectedCustomer(customer)
                      setShowModal({ name: 'New Order', show: true })
                    }}
                  >
                    New Order
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>{' '}
      {showModal.show && (
        <AdminModals showModal={showModal} setShowModal={setShowModal} />
      )}
    </div>
  )
}

export default AllCustomers
