import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

const TermsConditions = () => {
  const [show, setShow] = useState(true)
  const history = useHistory()

  return (
    <>
      <div>
        <Modal
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
          show={show}
          onHide={() => {
            setShow(false)
            history.goBack()
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Terms & Conditions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Modal.Title>General Terms and Conditions of Sale</Modal.Title>
            <p>
              In these Terms and Conditions, unless the context otherwise
              requires, the following expriessions have the following meanings:
              "We/Us/Our" means Avigenics INC ; "Avigenics" means the trading
              name of Avigenics INC, a company registered in USA under number
              13211134, whose registered office is at 1562 BELVEDERE AVE,Okemos,
              MI 48864, USA. Avigenics INC are the operators of this
              Website/Application; “Goods” means the goods advertised on the
              Website that we supply to you of the number and description as set
              out in the Order; "Website" means avigenicsusa.com on which the
              Goods are advertised "Application" means Avigenics Limited mobile
              application that you use for placing food orders These Terms and
              Conditions govern the sale of all goods by us and will form the
              basis of the contract between you and us. If you wish to place an
              order with us, our Website/Application will guide you through the
              ordering process. Before submitting your order to us, you will be
              given the opportunity to review and amend it. Please ensure that
              you have checked your order and that you have read these Terms and
              Conditions carefully before submitting it. If you are unsure about
              any part of these Terms and Conditions, please ask us for
              clarification.
            </p>
            <Modal.Title>Our Contract</Modal.Title>
            <p>
              All orders are subject to availability and confirmation of the
              order price. Dispatch times may vary according to availability and
              subject to any delays resulting from traffic delays or force
              majeure for which we will not be responsible. In order to contract
              with Avigenics INC you must be over 18 years of age and possess a
              valid credit or debit card issued by a bank acceptable to us.
              Avigenics INC retains the right to refuse any request made by you.
              If your order is accepted we will inform you by email, SMS or via
              the website. When placing an order, you undertake that all details
              you provide to us are true and accurate, that you are an
              authorised user of the credit or debit card used to place your
              order and that there are sufficient funds to cover the cost of the
              goods.
              <br />
              When you place an order, you will receive an acknowledgement
              e-mail and/or webpage notification confirming receipt of your
              order: this email and or webpage notification will only be an
              acknowledgement and will not constitute acceptance of your order.
              A contract between us will not be formed until we send you
              confirmation by the medium used for placing the order (SMS, e-mail
              or other appropriate digital means) that the goods which you
              ordered have been dispatched to you. Only those goods listed sent
              at the time of dispatch will be included in the contract formed.
            </p>
            <Modal.Title>Pricing and Availability</Modal.Title>{' '}
            <p>
              <br />
              Whilst we try to ensure that all details, descriptions and prices
              which appear on this Website/Application are accurate, errors may
              occur. If we discover an error in the price of any goods which you
              have ordered we will inform you of this as soon as possible and
              give you the option of reconfirming your order at the correct
              price or cancelling it. If we are unable to contact you we will
              treat the order as cancelled. If you cancel before your order has
              been prepared, and you have already paid for the goods, you will
              receive a full refund from Avigenics INC. Delivery costs will be
              charged in addition; such additional charges are clearly displayed
              where applicable and included in the 'Total Cost'.
            </p>
            <Modal.Title>Payment</Modal.Title>{' '}
            <p>
              All online card payments are made to Avigenics INC. Payments made
              to Avigenics INC will appear on your statement as 'Avigenics INC'.
              Upon receiving your order, we carry out a standard authorisation
              check on your payment card to ensure there are sufficient funds to
              fulfil the transaction. Your card will be debited upon
              authorisation being received. The monies received upon the
              debiting of your card shall be treated as a full payment against
              the value of the goods you wish to purchase. Once the goods have
              been despatched and you have been sent a confirmation email, SMS
              or notification on the website, the monies paid shall be used for
              the value of goods you have purchased as listed.
            </p>
            <Modal.Title>Delivery Estimates</Modal.Title>{' '}
            <p>
              Times given for collection of samples and delivery of the results
              are only approximate; Avigenics INC cannot provide an exact time
              for delivery but endeavour to deliver within the approximate
              delivery time specified when you place your order.
            </p>
            <Modal.Title>Price and Payment</Modal.Title>{' '}
            <p>
              The price of the Goods and any additional delivery or other
              charges is that set out on the website at the date of the order or
              such other price as we may agree in writing. Prices and charges
              include VAT at the rate applicable at the time of the Order. You
              must pay by submitting your credit or debit card details with your
              Order and we can take payment immediately or otherwise before
              delivery of the Goods.
            </p>
            <Modal.Title>
              Order Cancellation, Returns and Amendments
            </Modal.Title>{' '}
            <p>
              Once the order has been confirmed and payment taken from your
              account, you will be unable to cancel your order and will not be
              eligible for a refund. To change or cancel your order please
              contact Avigenics INC to attempt to resolve your request.
              Avigenics INC cannot guarantee your request will be accepted as
              samples processing in laboratory may already be underway. Once the
              goods are delivered, and the goods are accepted, the company will
              not accept returns.
            </p>
            <Modal.Title>Feedback</Modal.Title>{' '}
            <p>
              If the order received does not meet your expectation, you can
              provide feedback to Avigenics INC via telephone or email or on the
              website or the mobile application. Feedback submitted will be
              reviewed and may be displayed on Avigenics INC website, subject to
              the Customer Support team's sole discretion.
            </p>
            <Modal.Title>Compensation</Modal.Title>
            <p>
              If you are unhappy with the quality of any goods or the service
              provided by Avigenics INC and wish to seek any form of
              compensation, you should contact Avigenics INC directly to raise
              your complaint and, where appropriate, follow the Avigenics
              Limited complaints process. Avigenics INC will consider your
              complaint and attempt to resolve any dissatisfaction at its sole
              discretion. All complaints must be raised within 48 hours of the
              order delivery.
            </p>
            <Modal.Title>Customer Support</Modal.Title>
            <p>
              The Avigenics INC team will attempt to assist you and resolve any
              enquiry where possible regarding your order. You can contact the
              Avigenics INC Customer Support team by clicking "Contact Us" on
              the Website/Application and submitting your request via email or
              the contact form.
            </p>
            <Modal.Title>Personal information</Modal.Title>
            <p>
              We retain and use all information strictly under the Privacy
              Policy.
              <br />
              We may contact you by using e-mail or other electronic
              communication methods and by pre-paid post and you expressly agree
              to this.
            </p>
            <Modal.Title>Privacy Policy and Terms of Use</Modal.Title>
            <p>
              Your privacy is critical to us. We respect your privacy and comply
              with the General Data Protection Regulation with regard to your
              personal information. These Terms and Conditions should be read
              alongside, and are in addition to our policies, including our
              privacy policy (avigenics.eu) and cookies policy (avigenics.eu).
              <br />
              For the purposes of these Terms and Conditions:
              <br />
              a. 'Data Protection Laws' means any applicable law relating to the
              processing of Personal Data, including, but not limited to the
              Directive 95/46/EC (Data Protection Directive) or the GDPR.
              <br />
              b. 'GDPR' means the General Data Protection Regulation (EU)
              2016/679.
              <br />
              c. 'Data Controller', 'Personal Data' and 'Processing' shall have
              the same meaning as in the GDPR.
              <br />
              We are a Data Controller of the Personal Data we Process in
              providing Goods to you.
              <br />
              Where you supply Personal Data to us so we can provide Goods to
              you, and we Process that Personal Data in the course of providing
              the Goods to you, we will comply with our obligations imposed by
              the Data Protection Laws:
              <br />
              a. before or at the time of collecting Personal Data, we will
              identify the purposes for which information is being collected;
              <br />
              b. we will only Process Personal Data for the purposes identified;
              <br />
              c. we will respect your rights in relation to your Personal Data;
              and
              <br />
              d. we will implement technical and organisational measures to
              ensure your Personal Data is secure.
              <br />
              For any enquiries or complaints regarding data privacy, you can
              e-mail: info@avigenicsusa.com.
            </p>
            <Modal.Title>Variation</Modal.Title>
            <p>
              Avigenics INC shall have the right in its absolute discretion at
              any time and without notice to amend, remove or vary the Goods and
              or Services and/or any page of this Website/Application.
            </p>
            <Modal.Title>Invalidity</Modal.Title>
            <p>
              If any of the provisions of these Terms and Conditions are found
              to be unlawful, invalid or otherwise unenforceable by any court or
              other authority, that/those provision(s) will be deemed severed
              from the remainder of these Terms and Conditions and the remainder
              will be valid and enforceable.
            </p>
            <Modal.Title>Waiver</Modal.Title>
            <p>
              If you breach these conditions and we take no action, we will
              still be entitled to use our rights and remedies in any other
              situation where you breach these conditions.
            </p>{' '}
            <Modal.Title>Law and Jurisdiction</Modal.Title>
            <p>
              These Terms and Conditions, and the relationship between you and
              us (whether contractual or otherwise) shall be governed by and
              construed in accordance with the laws of USA. If you are a
              consumer, you will benefit from any mandatory provisions of the
              law in your country of residence. Nothing in the above clause
              takes away or reduces your rights as a consumer to rely on those
              provisions. Any dispute, controversy, proceedings or claim between
              you and us relating to these Terms and Conditions, or the
              relationship between you and us (whether contractual or otherwise)
              shall be subject to the jurisdiction of the courts of USA, as as
              determined by your residency.
            </p>
          </Modal.Body>
          <Modal.Footer>
            AVIGENICS INC <br /> Website: https://avigenicsusa.com
            <br /> Address: 1562 Belvedere Ave,Okemos, MI 48864, USA. <br />{' '}
            Tel: (313) 338-9022
          </Modal.Footer>
        </Modal>{' '}
      </div>
    </>
  )
}

export default TermsConditions
