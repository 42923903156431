import { Nav, Button } from 'react-bootstrap'
import { useAdmin } from '../../config/AdminContext'
import axios from 'axios'
import _ from 'lodash'
const LeftNav = () => {
  const { setClickedButton, setFilterEnabled, allBatches } = useAdmin()

  const updateResults = async () => {
    alert('Almost there')
    const resp = await axios.get('/api/rslt/') // call api
    const temp = resp.data // store response in a variable
    const set = _.chain(temp) // this is syntax  _ is imported from lodash npm module
      .groupBy('OrderID')
      .map((value, key) => ({ batchName: key, samples: value }))
      .value()

    console.log(set) // to see all orders individually there are 7 in the excel
    set.forEach((order) => {
      // console.log('Batch: ' + order.batchName)
      const keys_to_keep = ['ringNo', 'Results', 'selectedSpecies'] // just wanted these three -
      // ring no and species as combined unique key to update

      const redux1 = (list) =>
        list.map((o) => Object.fromEntries(keys_to_keep.map((k) => [k, o[k]]))) // copy paste from google

      const rslt = redux1(order.samples)

      const btu = allBatches.filter(
        // btu - batch to update
        (batch) => batch.batchName === order.batchName
      )[0]

      let merged = [] // merge batch.samples with results field

      for (let i = 0; i < btu.samples.length; i++) {
        merged.push({
          ...btu.samples[i], // original value from database before results
          ...rslt.find(
            (itmInner) =>
              itmInner.ringNo.toUpperCase() +
                itmInner.selectedSpecies.toUpperCase() === // find ringNo+species from both excel and from database to compare - dont want to use index if someone modifies excel
              btu.samples[i].ringNo.toUpperCase() +
                btu.samples[i].selectedSpecies.toUpperCase()
          ),
        })
      }

      console.log('Merged: ', merged) // this is samples with results field updated - we just need to store in DB
    })
  }

  return (
    <Nav variant='pills' defaultActiveKey='#All Orders' className='flex-column'>
      <Nav.Item>
        <Button
          block
          className='my-button'
          onClick={() => {
            setFilterEnabled(true)
            setClickedButton('All Orders')
          }}
        >
          All Orders
        </Button>
      </Nav.Item>
      <Nav.Item>
        <Button
          block
          className='my-button'
          onClick={() => {
            setFilterEnabled(true)
            setClickedButton()
          }}
        >
          Super Admin
        </Button>
      </Nav.Item>
      <Nav.Item>
        <Button
          block
          className='my-button'
          onClick={() => {
            setFilterEnabled(true)
            setClickedButton('In Progress')
          }}
        >
          In Progress
        </Button>
      </Nav.Item>
      <Nav.Item>
        <Button
          block
          className='my-button'
          onClick={() => {
            setFilterEnabled(true)
            setClickedButton('Posted')
          }}
        >
          Posted
        </Button>
      </Nav.Item>
      <Nav.Item>
        <Button
          block
          className='my-button'
          onClick={() => {
            setFilterEnabled(true)
            setClickedButton('Arrived')
          }}
        >
          Arrived
        </Button>
      </Nav.Item>
      <Nav.Item>
        <Button
          block
          className='my-button'
          onClick={() => {
            setFilterEnabled(true)
            setClickedButton('In Lab')
          }}
        >
          In Lab
        </Button>
      </Nav.Item>
      <Nav.Item>
        <Button
          block
          className='my-button'
          onClick={() => {
            setFilterEnabled(true)
            setClickedButton('Results Ready')
          }}
        >
          Results Ready
        </Button>
      </Nav.Item>{' '}
      <Nav.Item>
        <Button
          block
          className='my-button'
          key='#Completed'
          onClick={() => {
            setFilterEnabled(true)
            setClickedButton('Completed')
          }}
        >
          Completed
        </Button>
      </Nav.Item>
      {/* <Nav.Item>
        <Button
          block
          className='my-button'
          style={{ backgroundColor: 'green' }}
          onClick={() => {
            setFilterEnabled(true)
            setClickedButton('Credit Card')
          }}
        >
          Credit Card
        </Button>
      </Nav.Item> */}
      <Nav.Item>
        <Button
          block
          className='my-button'
          style={{ backgroundColor: 'red' }}
          onClick={() => {
            setFilterEnabled(true)
            setClickedButton('Kits Wanted')
          }}
        >
          Kits Wanted
        </Button>
      </Nav.Item>
      <Nav.Item>
        <Button
          block
          className='my-button'
          style={{ backgroundColor: 'green' }}
          onClick={() => {
            setFilterEnabled(true)
            setClickedButton('Certs Wanted')
          }}
        >
          Certs Wanted
        </Button>
      </Nav.Item>
      <Nav.Item>
        <Button
          block
          className='my-button'
          onClick={() => {
            setFilterEnabled(true)
            setClickedButton('All Customers')
          }}
        >
          All Customers
        </Button>
      </Nav.Item>
      <Nav.Item>
        <Button
          block
          className='my-button'
          onClick={() => {
            setFilterEnabled(true)
            setClickedButton('Update Prices')
          }}
        >
          Update Prices
        </Button>
      </Nav.Item>
      <Nav.Item>
        <Button
          block
          // active={clickedButton === 'Delete'}
          className='my-button'
          onClick={() => {
            setFilterEnabled(true)
            setClickedButton('Delete')
          }}
        >
          Deleted
        </Button>
      </Nav.Item>
      <Nav.Item>
        <Button
          block
          className='my-button'
          onClick={() => {
            updateResults()
            setClickedButton()
          }}
        >
          Update Results
        </Button>
      </Nav.Item>
    </Nav>
  )
}

export default LeftNav
