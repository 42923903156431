const Modify = () => {
  return (
    <>
      <h6>
        {' '}
        Yes. You can modify ring number or species name until you post it.
        <br />
        If you need to make changes after you post it, please contact our
        office.
      </h6>
      {/* <!-- Mission Section --> */}
      <section className='mission-section clearfix'>
        <div className='auto-container'>
          <div className='row clearfix'>
            {/* <!-- Image Column --> */}
            <div className='image-column col-lg-6 col-md-12 col-sm-12'>
              <div className='inner-column'>
                <div
                  className='image wow fadeInLeft'
                  data-wow-delay='0ms'
                  data-wow-duration='1500ms'
                >
                  <img
                    src='images/resource/spelling.png'
                    alt=''
                    style={{
                      borderRadius: '15px',
                      boxShadow: '7px 5px 5px 0px rgba(0,0,0,0.5)',
                    }}
                  />
                </div>
              </div>
            </div>

            {/* <!-- Content Column --> */}
            <div className='content-column col-lg-6 col-md-12 col-sm-12'>
              <div className='inner-column'>
                {/* <!-- Sec Title --> */}
                <div className='sec-title'>
                  {/* <div className='title'>AVIGENICS</div> */}
                  <h2>Corrections</h2>
                </div>
                <div className='bold-text'>
                  To make changes to ring no or species name, click on the
                  “order number” link, click on the edit icon for ring number to
                  modify..
                  <br />
                  Once changes are made, click on the “Save” button.
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Services Section Three --> */}
    </>
  )
}

export default Modify
