const ShellSample = () => {
  return (
    <>
      {/* <!-- Page Title --> */}
      {/* <section className='page_breadcrumb'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <ol className='page_bread'>
                <li>
                  <a href='/'>Home</a>
                </li>
                <li className='current_page'>Egg Shell Sample</li>
              </ol>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!-- End Page Title --> */}

      {/* <!-- Mission Section --> */}
      <section className='mission-section clearfix'>
        <div className='auto-container'>
          <div className='row clearfix'>
            {/* <!-- Image Column --> */}
            <div className='image-column col-lg-6 col-md-12 col-sm-12'>
              <div className='inner-column'>
                <div
                  className='image wow fadeInLeft'
                  data-wow-delay='0ms'
                  data-wow-duration='1500ms'
                >
                  <img
                    src='images/resource/eggshell.png'
                    alt=''
                    style={{
                      borderRadius: '15px',
                      boxShadow: '7px 5px 5px 0px rgba(0,0,0,0.5)',
                    }}
                  />
                </div>
              </div>
            </div>

            {/* <!-- Content Column --> */}
            <div className='content-column col-lg-6 col-md-12 col-sm-12'>
              <div className='inner-column'>
                {/* <!-- Sec Title --> */}
                <div className='sec-title'>
                  {/* <div className='title'>AVIGENICS</div> */}
                  <h2>Egg Shell Sample</h2>
                </div>
                <div className='bold-text'>
                  We are currently NOT accepting Egg shell samples.
                  <br /> If you still wish to send Egg Shells, please send us
                  but it is your own risk. If we cannot extract DNA, we may ask
                  for repeat samples and you may have to send blood or feathers.
                  For more information, please write to us at:
                  <a href='mailto:info@avigenicsusa.com'>
                    info@avigenicsusa.com
                  </a>{' '}
                  or call us on{' '}
                  <a href='tel:+13133389022'>+ 1 (313) 338-9022</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Services Section Three --> */}
    </>
  )
}

export default ShellSample
