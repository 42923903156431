export const SMSTemplates = [
  {
    name: '',
    text: '',
  },
  {
    name: 'Order Not Posted',
    text: 'Hi, You have created an order on our website but it shows still not posted. Please let us know, if you need any help from us.\nThanks,\nTeam Avigenics',
  },
  {
    name: 'Payment Please',
    text: 'Hi, With regards to your recent order, could you please contact us on + 1 (313) 338-9022 for the payment.\nThanks,\nAvigenics',
  },
  {
    name: 'Order Not received',
    text: 'Hi, You have created an order on our website. It shows posted, but we have not received. Could you please confirm if you have posted it?\nThanks,\nTeam Avigenics',
  },
  {
    name: 'Results Ready',
    text: "Hello,\nWe have sent results for your recent order by email.\nPlease check Junk/Spam Mail also. Still can't find ? Please call us + 1 (313) 338-9022.\n- Team Avigenics",
  },
  {
    name: 'Your Email is wrong',
    text: 'Hello,\nWe tried to send an email regarding your order with Avigenics. The mail was bounced. Could you please send the correct email address?\n- Team Avigenics',
  },
  {
    name: 'Repeat Samples',
    text: 'Hi, With regards to your recent order, We could not extract DNA or could not get clear results for one or more samples. We need fresh samples to process this. Please contact us for more information.\nThanks,\nAvigenics',
  },
  {
    name: 'Type own message',
    text: '\n- Team Avigenics',
  },
]
