import { React, useState } from 'react'
import { NavDropdown, Nav, Navbar } from 'react-bootstrap'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'font-awesome/css/font-awesome.min.css'
import { Link } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { useAuth } from '../config/AuthContext'

// import '../config/FirebaseConfig'

const Header = () => {
  const { currentUser } = useAuth()
  const [expanded, setExpanded] = useState(false)
  const handleExpand = () => {
    setExpanded(expanded ? false : 'expanded')
    setTimeout(() => {
      setExpanded(false)
    }, 10000)
  }

  return (
    <div>
      <Navbar
        expanded={expanded}
        expand='lg'
        className='nav-color'
        variant='light'
        fixed='top'
      >
        <Navbar.Brand>
          <div className='logo'>
            <a href='/'>
              <img src='../images/avi-logo-white.png' alt='' title='' />
            </a>
          </div>{' '}
        </Navbar.Brand>
        <Navbar.Toggle
          onClick={handleExpand}
          aria-controls='responsive-navbar-nav'
          // style={{ color: '#ffffff' }}
        >
          <div>
            <i className='fa fa-bars' style={{ color: '#ffffff' }}></i>
          </div>
        </Navbar.Toggle>
        <Navbar.Collapse
          id='responsive-navbar-nav'
          variant='light'
          style={{ color: '#ffffff' }}
        >
          <Nav className='mr-auto '>
            <Nav.Link
              as={Link}
              to='/'
              style={{
                color: 'white',
                fontWeight: 'bold',
                paddingRight: '15px',
              }}
            >
              Home
            </Nav.Link>
            <Nav.Link
              as={Link}
              to='/Price'
              style={{
                color: 'white',
                fontWeight: 'bold',
                paddingRight: '15px',
              }}
              onClick={handleExpand}
            >
              Price
            </Nav.Link>
            <NavDropdown
              id='aaa'
              style={{ color: 'white' }}
              title={
                <span
                  className='text-white my-auto'
                  style={{
                    color: 'white',
                    fontWeight: 'bold',
                    paddingRight: '1px',
                  }}
                >
                  Services
                </span>
              }
              // id='collasible-nav-dropdown'
            >
              <NavDropdown.Item
                as={Link}
                to='/DNASexing'
                onClick={handleExpand}
              >
                DNA Sexing
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to='/PBFD' onClick={handleExpand}>
                PBFD Testing
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to='/APV' onClick={handleExpand}>
                Avian Polyoma Virus{' '}
              </NavDropdown.Item>
            </NavDropdown>{' '}
            <Nav.Link
              as={Link}
              to='/FAQ'
              style={{
                color: 'white',
                fontWeight: 'bold',
                paddingRight: '15px',
              }}
              onClick={handleExpand}
            >
              F.A.Q.
            </Nav.Link>
            <Nav.Link
              as={Link}
              onClick={handleExpand}
              to='/ContactUs'
              style={{
                color: 'white',
                fontWeight: 'bold',
                paddingRight: '15px',
              }}
            >
              Contact Us
            </Nav.Link>
            <Nav.Link
              as={Link}
              onClick={handleExpand}
              to='/SampleCollection'
              style={{
                color: 'white',
                fontWeight: 'bold',
                paddingRight: '15px',
              }}
            >
              Sample Collection
            </Nav.Link>
          </Nav>
          <Nav className='mr-auto'>
            <Nav.Link as={Link} to='/OrderNow' onClick={handleExpand}>
              <span
                className='blink_me'
                style={{
                  color: 'yellow',
                  fontWeight: 'bold',
                  paddingRight: '25px',
                }}
                onClick={handleExpand}
              >
                Order Now
              </span>
            </Nav.Link>
            <Nav.Link
              as={Link}
              to='/MyAccount'
              style={{
                color: 'black',
                fontWeight: 'bold',
                paddingRight: '25px',
              }}
              onClick={handleExpand}
            >
              <span
                style={{
                  color: 'white',
                  fontWeight: 'bold',
                  paddingRight: '25px',
                }}
              >
                My Account
              </span>
            </Nav.Link>
            {currentUser ? (
              <Nav.Link
                as={Link}
                to='/'
                style={{
                  color: 'white',
                  fontWeight: 'bold',
                  paddingRight: '25px',
                }}
                onClick={async () => {
                  await firebase.auth().signOut()
                  handleExpand()
                  // history.replace('/')
                }}
              >
                Logout
              </Nav.Link>
            ) : (
              <></>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div>
        <ToastContainer
          className='col-lg-6 fixed-top'
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          limit='1'
          style={{ whiteSpace: 'pre-line', textAlign: 'center' }}
          // onClick={() => window.scrollTo(0, 400)}
        />
      </div>
      <hr style={{ height: '43.8pt', visibility: 'hidden' }} />
    </div>
  )
}

export default Header
