import React, { useState } from 'react'
import { Form, Button, Modal, Container, Card, Alert } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { useHistory, Link } from 'react-router-dom'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import { toast } from 'react-toastify'
import axios from 'axios'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import usePlacesAutocomplete, {
  getGeocode,
  getZipCode,
} from 'use-places-autocomplete'
import useOnclickOutside from 'react-cool-onclickoutside'

const Register = () => {
  // const [showLogin, setShowLogin] = useState(false)
  const [showRegister, setShowRegister] = useState(true)
  const history = useHistory()
  const db = firebase.firestore()
  const [error, setError] = useState('')
  const [dontAutoCorrect, setDontAutoCorrect] = useState(false)
  const [phone, setPhone] = useState(0)
  const [customer, setCustomer] = useState()
  const [pincode, setPincode] = useState('')

  // const [landphone, setLandPhone] = useState(0)

  ///////// GEO LOCATION //////////
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ['address'],
      componentRestrictions: { country: 'us' },
      /* Define search scope here */
    },
    debounce: 300,
  })

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions()
  })

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value)
    // setCustomer({ ...customer, address: e.target.value })
  }

  const handleSelect =
    ({ description }) =>
    () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false)
      // setCustomer({ ...customer, address: description })
      getGeocode({ address: description })
        // By default we use the "long_name" value from API response, you can tell the utility to use "short_name"
        // by setting the second parameter to "true"
        .then((results) => {
          //       console.log(results[0])
          const zipCode = getZipCode(results[0], false)
          // alert(zipCode)
          setCustomer({ ...customer, pincode: zipCode, address: description })
          setPincode(zipCode)
        })
      clearSuggestions()

      // Get latitude and longitude via utility functions
    }

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion

      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      )
    })

  ///////// GEO LOCATION //////////

  // Auto Correct
  const handleChangeName = (e) => {
    const fieldValue = e.target.value
    if (!dontAutoCorrect) {
      const correctedName = fieldValue.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      })
      e.target.value = correctedName
    }
  }

  const sendMail = async (email, name) => {
    await axios.post('/api/sendmail/', {
      to: email,
      text: `      <div class='col-lg-6'>
        <div>
          
          <p>Dear ${name},</p>
          <p>Welcome to Avigenics. <br/>Thank you for registering with us. <br/>You can place the orders online now.</p>
          <p>
            Please take a moment to review our
            <a href='https://avigenicsusa.com/FAQ'>Frequently Asked Questions </a>section.
          </p>
          <p>
            Thanks & Regards,
            <br />
           
                      <img
            style='width: 200px;' height: 100px;' }}
            src='https://avigenicsusa.com/images/logo-6.png'
          />
          </p>
          <p>E-mail:   info@avigenicsusa.com |   Phone: (313) 338-9022 <br/>
            1562 Belvedere Ave, Okemos, MI 48864, USA. <br/>
            The contents of this e-mail (including any attachments) may be the proprietary and confidential
          </p>
        </div>
      </div>`,
      subject: 'Welcome to Avigenics',
    })
  }
  const userRegister = async (event) => {
    //   alert(event.target.elements.State.value)
    event.preventDefault()
    const password = event.target.elements.formBasicPassword.value
    const confpass = event.target.elements.formBasicPassword1.value
    const email = event.target.elements.formBasicEmail.value.toLowerCase()
    const name = event.target.elements.formBasicName.value
    //  const phone = event.target.elements.formBasicPhone.value
    //const landphone = event.target.elements.formBasicLandPhone.value
    // const address1 = event.target.elements.formBasicAddress1.value
    // const address2 = event.target.elements.formBasicAddress2.value
    //   const city = event.target.elements.formBasicCity.value
    const pincode = event.target.elements.formZipcode.value
    //   const state = event.target.elements.formState.value
    const address = value

    if (password !== confpass) {
      setError('Passwords did not match.')
    } else {
      const created = Date.now()
      await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(async (result) => {
          toast('User created Successfully!', { type: 'success' })
          await db.collection('Customers').add({
            email,
            name,
            phone,
            address,

            //      address1,
            //      address2,
            //      city,
            pincode,
            created,
            //         state,
          })
          setShowRegister(false)
          result.user.updateProfile({
            displayName: name,
          })
          sendMail(email, name)
          history.push('/OrderNow')
        })
        .catch((error) => {
          setError(error.message)
        })
    }
  }

  return (
    <>
      <div>
        <Modal
          backdrop='static'
          keyboard={false}
          show={showRegister}
          onHide={() => {
            setShowRegister(false)
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Container
            className=' d-flex align-items-center justify-content-center'
            style={{ minHeight: '20vh' }}
          >
            <div className='w-100' style={{ maxWidth: '400px' }}>
              <Card>
                <Card.Body>
                  <h2 className='text-center mb-4'>Register</h2>
                  {error && <Alert variant='danger'>{error}</Alert>}
                  <Form.Text className='text-muted'>
                    * These fields are mandatory.
                  </Form.Text>
                  <Form onSubmit={userRegister}>
                    <Form.Group controlId='formBasicName'>
                      <Form.Control
                        type='text'
                        required
                        placeholder='Full Name *'
                        onChange={handleChangeName}
                      />
                    </Form.Group>
                    <Form.Check
                      type='checkbox'
                      checked={dontAutoCorrect}
                      label='Dont Auto Correct My Name'
                      onChange={() => setDontAutoCorrect(!dontAutoCorrect)}
                    />
                    {/* <Form.Group controlId='formBasicLandPhone'>
                      <PhoneInput
                        country={'au'}
                        value={phone}
                        placeholder='Land Phone'
                        onChange={(num1) => setLandPhone(num1)}
                      />
                    </Form.Group> */}
                    <Form.Group controlId='formBasicPhone'>
                      <PhoneInput
                        country={'us'}
                        value={phone}
                        laceholder='Mobile Phone'
                        onChange={(num) => setPhone(num)}
                      />
                    </Form.Group>
                    <Form.Group>
                      {/* <Form.Text className='text-muted'>Address</Form.Text>{' '} */}
                      <div ref={ref}>
                        <Form.Control
                          as='textarea'
                          rows={5}
                          value={value}
                          onChange={handleInput}
                          disabled={!ready}
                          placeholder='Address'
                        />
                        {/* We can use the "status" to decide whether we should display the dropdown or not */}
                        {status === 'OK' && <ul>{renderSuggestions()}</ul>}
                      </div>
                    </Form.Group>
                    <Form.Group controlId='formZipcode'>
                      <Form.Control
                        type='text'
                        required
                        placeholder='Zip Code *'
                        value={pincode}
                        onChange={(e) => {
                          setPincode(e.target.value)
                        }}
                        //    onChange={handleChangeName}
                      />
                    </Form.Group>
                    <Form.Group controlId='formBasicEmail'>
                      <Form.Control
                        type='email'
                        required
                        placeholder='Email address *'
                      />
                      <Form.Text className='text-muted'>
                        Please specify a valid email address.
                      </Form.Text>
                    </Form.Group>
                    <Form.Group controlId='formBasicPassword'>
                      <Form.Control
                        type='password'
                        required
                        placeholder='Password *'
                      />
                    </Form.Group>
                    <Form.Group controlId='formBasicPassword1'>
                      <Form.Control
                        type='password'
                        required
                        placeholder='Re-enterPassword *'
                      />
                    </Form.Group>
                    <Button
                      variant='secondary'
                      onClick={() => {
                        setShowRegister(false)
                        // history.push('/Login')
                      }}
                    >
                      Cancel
                    </Button>{' '}
                    <Button variant='primary' type='submit' value='Submit'>
                      Register
                    </Button>
                    <Form.Text className='form-text'>
                      Already registered? Login
                      <Link
                        to='/Login'
                        onClick={() => {
                          setShowRegister(false)
                          // setShowLogin(true)
                        }}
                      >
                        here
                      </Link>
                    </Form.Text>
                  </Form>
                </Card.Body>
              </Card>
            </div>
          </Container>
          <Modal.Footer></Modal.Footer>
        </Modal>{' '}
      </div>
    </>
  )
}

export default Register
