import { Button, Accordion, Card } from 'react-bootstrap'
import AddDelete from './AddDelete'
// import BloodSample from './BloodSample'
import CreateOnline from './CreateOnline'
// import FeatherSample from './FeatherSample'
// import ShellSample from './ShellSample'
import Modify from './Modify'
import Post from './Post'
import { useEffect } from 'react'

const FAQ = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <>
        <section
          className='mission-section clearfix'
          style={{ padding: '2px', margin: '5px' }}
        >
          <div className='auto-container'>
            <div className='row clearfix'>
              <div className='content-column col-lg-12 col-md-12 col-sm-12'>
                <div className='inner-column'>
                  <div className='sec-title'>
                    <br />
                    <br />
                    <div className='title'>Frequently Asked Questions</div>
                  </div>
                  <Accordion>
                    {/* <Card style={{ border: 'none' }}>
                      <Card.Header
                        style={{ padding: '0px', backgroundColor: 'white' }}
                      >
                        <Accordion.Toggle
                          as={Button}
                          variant='link'
                          eventKey={'0'}
                        >
                          <h5 align='left'>How to collect Blood samples? </h5>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={'0'}>
                        <Card.Body>
                          <BloodSample />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card> */}
                    {/* <Card style={{ border: 'none' }}>
                      <Card.Header
                        style={{ padding: '0px', backgroundColor: 'white' }}
                      >
                        <Accordion.Toggle
                          as={Button}
                          variant='link'
                          eventKey={'1'}
                        >
                          <h5 align='left'>How to collect Feather samples? </h5>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={'1'}>
                        <Card.Body>
                          <FeatherSample />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card> */}
                    {/* <Card style={{ border: 'none' }}>
                      <Card.Header
                        style={{ padding: '0px', backgroundColor: 'white' }}
                      >
                        <Accordion.Toggle
                          as={Button}
                          variant='link'
                          eventKey={'2'}
                        >
                          <h5 align='left'>
                            How to collect Egg Shell samples?{' '}
                          </h5>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={'2'}>
                        <Card.Body>
                          <ShellSample />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card> */}
                    <Card style={{ border: 'none' }}>
                      <Card.Header
                        style={{ padding: '0px', backgroundColor: 'white' }}
                      >
                        <Accordion.Toggle
                          as={Button}
                          variant='link'
                          eventKey={'3'}
                        >
                          <h5 align='left'>
                            Do birds have to be of any age to test?{' '}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <i
                              className='fa fa-angle-down'
                              aria-hidden='true'
                            ></i>
                          </h5>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={'3'}>
                        <Card.Body>
                          <h6>
                            For most of the birds, you can collect samples when
                            the bird is about three weeks old.{' '}
                          </h6>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>{' '}
                    <Card style={{ border: 'none' }}>
                      <Card.Header
                        style={{ padding: '0px', backgroundColor: 'white' }}
                      >
                        <Accordion.Toggle
                          as={Button}
                          variant='link'
                          eventKey={'4'}
                        >
                          <h5 align='left'>
                            How to create order online? &nbsp;&nbsp;&nbsp;&nbsp;
                            <i
                              className='fa fa-angle-down'
                              aria-hidden='true'
                            ></i>
                          </h5>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={'4'}>
                        <Card.Body>
                          <CreateOnline />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card style={{ border: 'none' }}>
                      <Card.Header
                        style={{ padding: '0px', backgroundColor: 'white' }}
                      >
                        <Accordion.Toggle
                          as={Button}
                          variant='link'
                          eventKey={'5'}
                        >
                          <h5 align='left'>
                            Can I add or delete samples later?{' '}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <i
                              className='fa fa-angle-down'
                              aria-hidden='true'
                            ></i>
                          </h5>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={'5'}>
                        <Card.Body>
                          <AddDelete />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card style={{ border: 'none' }}>
                      <Card.Header
                        style={{ padding: '0px', backgroundColor: 'white' }}
                      >
                        <Accordion.Toggle
                          as={Button}
                          variant='link'
                          eventKey={'6'}
                        >
                          <h5 align='left'>
                            I made a mistake in ring no or species. Can I
                            correct? &nbsp;&nbsp;&nbsp;&nbsp;
                            <i
                              className='fa fa-angle-down'
                              aria-hidden='true'
                            ></i>
                          </h5>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={'6'}>
                        <Card.Body>
                          <Modify />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>{' '}
                    <Card style={{ border: 'none' }}>
                      <Card.Header
                        style={{ padding: '0px', backgroundColor: 'white' }}
                      >
                        <Accordion.Toggle
                          as={Button}
                          variant='link'
                          eventKey={'10'}
                        >
                          <h5 align='left'>
                            I have created order online what should I do next?{' '}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <i
                              className='fa fa-angle-down'
                              aria-hidden='true'
                            ></i>
                          </h5>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={'10'}>
                        <Card.Body>
                          <Post />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>{' '}
                    <Card style={{ border: 'none' }}>
                      <Card.Header
                        style={{ padding: '0px', backgroundColor: 'white' }}
                      >
                        <Accordion.Toggle
                          as={Button}
                          variant='link'
                          eventKey={'7'}
                        >
                          <h5 align='left'>
                            When should I pay? &nbsp;&nbsp;&nbsp;&nbsp;
                            <i
                              className='fa fa-angle-down'
                              aria-hidden='true'
                            ></i>
                          </h5>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={'7'}>
                        <Card.Body>
                          <h6>
                            You can pay for the order as soon as you create
                            order or after posted but we should receive the
                            payment before we start processing.
                          </h6>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>{' '}
                    <Card style={{ border: 'none' }}>
                      <Card.Header
                        style={{ padding: '0px', backgroundColor: 'white' }}
                      >
                        <Accordion.Toggle
                          as={Button}
                          variant='link'
                          eventKey={'8'}
                        >
                          <h5 align='left'>
                            How can I pay? &nbsp;&nbsp;&nbsp;&nbsp;
                            <i
                              className='fa fa-angle-down'
                              aria-hidden='true'
                            ></i>
                          </h5>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={'8'}>
                        <Card.Body>
                          <h6>
                            Using credit/debit card:
                            <br />
                            Click on pay now button and enter card details
                            <br /> Pay by Phone:
                            <br />
                            Please call us on + 1 (313) 338-9022 and we will
                            take payment over phone.
                            <br />
                            Pay by cheque: <br />
                            Please make cheque payable to ‘AVIGENICS INC’ and
                            send it along with samples. <br />
                            Pay by Bank Transfer: <br />
                            Use your internet banking to pay. Our bank details:
                            <br />
                            Account Name: AVIGENICS INC <br />
                            Account No : 936128591 <br />
                            Routing No : 072000326
                            <br /> Reference: Your order number
                          </h6>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>{' '}
                    <Card style={{ border: 'none' }}>
                      <Card.Header
                        style={{ padding: '0px', backgroundColor: 'white' }}
                      >
                        <Accordion.Toggle
                          as={Button}
                          variant='link'
                          eventKey={'11'}
                        >
                          <h5 align='left'>
                            What is the cost for a DNA test?{' '}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <i
                              className='fa fa-angle-down'
                              aria-hidden='true'
                            ></i>
                          </h5>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={'11'}>
                        <Card.Body>
                          <h6>
                            For latest prices, please see{' '}
                            <a href={'/Price'}>Price</a> section on this website
                          </h6>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>{' '}
                    <Card style={{ border: 'none' }}>
                      <Card.Header
                        style={{ padding: '0px', backgroundColor: 'white' }}
                      >
                        <Accordion.Toggle
                          as={Button}
                          variant='link'
                          eventKey={'12'}
                        >
                          <h5 align='left'>
                            How long should I wait for results?{' '}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <i
                              className='fa fa-angle-down'
                              aria-hidden='true'
                            ></i>
                          </h5>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={'12'}>
                        <Card.Body>
                          <h6>
                            Once we receive the samples (we will send you an
                            email once we receive the samples), it will normally
                            take 5-7 Business days for the results to arrive.
                            PBFD and APV results will be available a bit later -
                            these tests need extra time - usually another day or
                            two.
                          </h6>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>{' '}
                    <Card style={{ border: 'none' }}>
                      <Card.Header
                        style={{ padding: '0px', backgroundColor: 'white' }}
                      >
                        <Accordion.Toggle
                          as={Button}
                          variant='link'
                          eventKey={'13'}
                        >
                          <h5 align='left'>
                            Will you send physical certificate?{' '}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <i
                              className='fa fa-angle-down'
                              aria-hidden='true'
                            ></i>
                          </h5>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={'13'}>
                        <Card.Body>
                          <h6>
                            You can purchase physical certificates at an extra
                            cost.
                            <br />
                            For latest prices, please see{' '}
                            <a href={'/Price'}>Price</a> section on this website
                            or please contact our office for more details.
                            <br />
                          </h6>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>{' '}
                    <Card style={{ border: 'none' }}>
                      <Card.Header
                        style={{ padding: '0px', backgroundColor: 'white' }}
                      >
                        <Accordion.Toggle
                          as={Button}
                          variant='link'
                          eventKey={'14'}
                        >
                          <h5 align='left'>
                            I have not received certificates email. Why?{' '}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <i
                              className='fa fa-angle-down'
                              aria-hidden='true'
                            ></i>
                          </h5>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={'14'}>
                        <Card.Body>
                          <h6>
                            If your order status is showing as complete and you
                            did not receive certificate in email, your e-mail
                            system might have blocked it.
                            <br />
                            Please check your Spam/Junk mail and if you still
                            can't find it, please let us know and we will send
                            them in another method.
                            <br />
                          </h6>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>{' '}
                    <Card style={{ border: 'none' }}>
                      <Card.Header
                        style={{ padding: '0px', backgroundColor: 'white' }}
                      >
                        <Accordion.Toggle
                          as={Button}
                          variant='link'
                          eventKey={'15'}
                        >
                          <h5 align='left'>
                            Can I view/download certificates from the portal?{' '}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <i
                              className='fa fa-angle-down'
                              aria-hidden='true'
                            ></i>
                          </h5>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={'15'}>
                        <Card.Body>
                          <h6>
                            Yes. You can select completed orders and click on
                            ring id to see certificate
                            <br />
                            Click on save button. You can also print this
                            certificate
                            <br />
                          </h6>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>{' '}
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </>
  )
}

export default FAQ
