import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import firebase from 'firebase/app'
import 'firebase/firestore'
const PriceConfig = firebase.firestore().collection('config')

const CurrentPrices = () => {
  const [config, setConfig] = useState({})
  useEffect(() => {
    getPriceConfig()
  }, [])

  const getPriceConfig = async () => {
    await PriceConfig.onSnapshot(
      (querySnapshot) => {
        let rcvdConfig = []
        querySnapshot.forEach((doc) => {
          rcvdConfig.push({ id: doc.id, ...doc.data() })
        })
        setConfig(rcvdConfig[0])
      },
      (err) => {
        return null
      }
    )
  }
  return (
    <div
      className='content-column col-lg-6 col-md-12 col-sm-12'
      style={{ margin: '0px', marginTop: '60px' }}
    >
      <div className='inner-column'>
        <div className='sec-title'>
          <h5>Current Price for DNA tests:</h5>
        </div>
        <div className='bold-text'>
          <h6 style={{ backgroundColor: 'yellow' }}>
            For 1 to {config && config.slab1Target} samples, $
            {config && config.dnaOnlyPrice} for each sample. <br />
            {config && Number(config.slab1Target) + 1} to{' '}
            {config && Number(config.slab2Target)} samples, $
            {config && config.dnaSlab1Price} for each sample. <br />
          </h6>{' '}
          <h5>Current Price for PBFD tests:</h5>
          <h6 style={{ backgroundColor: 'yellow' }}>
            For 1 to {config && config.slab1Target} samples, $
            {config && config.pbfdOnlyPrice} for each sample. <br />
            {config && Number(config.slab1Target) + 1} to{' '}
            {config && Number(config.slab2Target)} samples, $
            {config && config.pbfdSlab1Price} for each sample. <br />
          </h6>{' '}
          <h5>Current Price for APV tests:</h5>
          <h6 style={{ backgroundColor: 'yellow' }}>
            For 1 to {config && config.slab1Target} samples, $
            {config && config.apvOnlyPrice} for each sample. <br />
            {config && Number(config.slab1Target) + 1} to{' '}
            {config && Number(config.slab2Target)} samples, $
            {config && config.apvSlab1Price} for each sample. <br />
          </h6>
          For full list of latest prices, please visit{' '}
          <Link to='/price'>Price </Link>
          page.
        </div>
      </div>
    </div>
  )
}

export default CurrentPrices
