import { useEffect } from 'react'
import { useAdmin } from '../../config/AdminContext'
import ComponentToShow from './ComponentToShow'

const InLab = () => {
  const { findFiltered, allBatches } = useAdmin()
  useEffect(() => {
    findFiltered('In Lab')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allBatches])

  return <ComponentToShow prev={'Arrived'} next={'Results Ready'} />
}

export default InLab
