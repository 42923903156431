import { useState } from 'react'
import { Button, Col, Nav, Table } from 'react-bootstrap'
import { useAdmin } from '../../config/AdminContext'
import AdminModals from './AdminModals'
import axios from 'axios'

const sendEmail = async (id, name, email) => {
  try {
    await axios.post('/api/sendmail/', {
      to: email,
      text: `Dear ${name}, <br/><br/>We have posted your certificates today.<br/>They should arrive in 4-5 days, if not, please contact us.<br/>
        
<p>Thanks & Regards,
            <br />
            Team Avigenics<br/><br/>
                      <img
            style='width: 150px;' height: 80px;' }}
            src='https://avigenicsusa.com/images/logo-6.png'
          /><br/><br/>
E-mail:   info@avigenicsusa.com  |   Phone:   (313) 338-9022 <br/>
            Avigenics Inc,1562 Belvedere Ave,Okemos, MI 48864, USA. <br/>
            The contents of this e-mail (including any attachments) may be the proprietary and confidential
          </p>`,
      subject: `Order ID: ${id}`,
      bcc: 'orders@avigenicsusa.com',
    })
  } catch (error) {
    console.log(error)
  }
}

const CertsWanted = () => {
  const { getCustomer, setSelectedBatch, certsSent, certsWanted } = useAdmin()
  const [showModal, setShowModal] = useState({ name: '', show: false })

  return (
    <>
      <Col sm={10}>
        <Table responsive size='sm'>
          <thead>
            <tr>
              <th>Customer Name</th>
              <th>E-Mail</th>
              <th>Order id</th>
              <th>Samples</th>
              <th>Certs Type</th>
              <th>Sent?</th>
            </tr>
          </thead>
          <tbody>
            {certsWanted &&
              certsWanted.map((batch) => (
                <tr
                  key={batch.id}
                  style={{
                    backgroundColor:
                      (batch.status === 'Completed' && '#00cc00') ||
                      (batch.status === 'Results Ready' && 'lightgreen') ||
                      (batch.status === 'In Progress' && '#CCCC00') ||
                      (batch.status === 'Posted' && '#CCCCCC') ||
                      (batch.status === 'In Lab' && '#9ef') ||
                      (batch.status === 'Arrived' && 'pink') ||
                      (batch.status === 'Delete' && 'salmon'),
                    fontStyle: batch.orderType === 'Manual' && 'italic',
                    textDecoration: batch.orderType === 'Manual' && 'underline',
                  }}
                >
                  {batch.certsNeeded && !batch.certsNeeded.sent && (
                    <>
                      <td>
                        <Nav.Link
                          onClick={() => {
                            getCustomer(batch.custId)
                            setShowModal({ name: 'Customer', show: true })
                          }}
                        >
                          {batch.customerName}
                        </Nav.Link>
                      </td>
                      <td>{batch.custId}</td>
                      <td>
                        <Nav.Link
                          onClick={() => {
                            setSelectedBatch(batch)
                            setShowModal({
                              name: 'Order Details',
                              show: true,
                            })
                          }}
                        >
                          {batch.batchName}
                        </Nav.Link>
                      </td>
                      <td>{batch.samples.length}</td>
                      <td>{batch.certsNeeded.type}</td>
                      <td>
                        <Button
                          onClick={() => {
                            certsSent(batch.id, batch.certsNeeded)
                            sendEmail(
                              batch.batchName,
                              batch.customerName,
                              batch.custId
                            )
                          }}
                        >
                          Posted Now
                        </Button>
                      </td>
                    </>
                  )}
                </tr>
              ))}
          </tbody>
          <tbody>
            {certsWanted &&
              certsWanted.map((batch) => (
                <tr
                  key={batch.id}
                  style={{
                    backgroundColor:
                      (batch.status === 'Completed' && '#00cc00') ||
                      (batch.status === 'Results Ready' && 'lightgreen') ||
                      (batch.status === 'In Progress' && '#CCCC00') ||
                      (batch.status === 'Posted' && '#CCCCCC') ||
                      (batch.status === 'In Lab' && '#9ef') ||
                      (batch.status === 'Arrived' && 'pink') ||
                      (batch.status === 'Delete' && 'salmon'),
                    fontStyle: batch.orderType === 'Manual' && 'italic',
                    textDecoration: batch.orderType === 'Manual' && 'underline',
                  }}
                >
                  {batch.certsNeeded && batch.certsNeeded.sent && (
                    <>
                      <td>
                        <Nav.Link
                          onClick={() => {
                            getCustomer(batch.custId)
                            setShowModal({ name: 'Customer', show: true })
                          }}
                        >
                          {batch.customerName}
                        </Nav.Link>
                      </td>
                      <td>{batch.custId}</td>
                      <td>
                        <Nav.Link
                          onClick={() => {
                            setSelectedBatch(batch)
                            setShowModal({
                              name: 'Order Details',
                              show: true,
                            })
                          }}
                        >
                          {batch.batchName}
                        </Nav.Link>
                      </td>
                      <td>{batch.samples.length}</td>
                      <td>{batch.certsNeeded.type}</td>
                      <td>{batch.certsNeeded.sent}</td>
                    </>
                  )}
                </tr>
              ))}
          </tbody>
        </Table>
      </Col>
      {showModal.show && (
        <AdminModals showModal={showModal} setShowModal={setShowModal} />
      )}
    </>
  )
}

export default CertsWanted
