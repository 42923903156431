import * as htmlToImage from 'html-to-image'
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/storage'
import { Image } from 'react-bootstrap'
import downloadjs from 'downloadjs'
import moment from 'moment'
import { useAdmin } from '../../config/AdminContext'
import 'firebase/database'
import 'firebase/storage'

import axios from 'axios'
import { useOrder } from '../../config/OrderContext'

const Invoice = (props) => {
  const { selectedBatch, selectedCustomer } = useAdmin()
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FB_API_KEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FB_APP_ID,
  }
  let fbConfig
  if (!firebase.apps.length) {
    fbConfig = firebase.initializeApp(firebaseConfig)
  } else {
    fbConfig = firebase.app() // if already initialized, use that one
  }

  const { payDetails } = useOrder()
  let { dna, pbfd, apv, quantity, kits, postage, cert1, cert2 } = payDetails
  const curr = new Date()
  curr.setDate(curr.getDate())
  const date = moment(curr.toISOString().split('T')[0]).format('DD/MM/YYYY')

  if (!firebase.apps.length) {
    fbConfig = firebase.initializeApp(firebaseConfig)
  } else {
    fbConfig = firebase.app() // if already initialized, use that one
  }

  const savePdf = async () => {
    await htmlToImage
      .toBlob(document.getElementById('INVrig'))
      .then(async (blob) => {
        const imgData = await blob
        const storageRef = await fbConfig.storage().ref()
        const fileRef = storageRef.child(
          `Invoices/${selectedBatch.batchName}.png`
        )
        await fileRef.put(imgData)
        downloadjs(imgData, 'download.png', 'image/png')
      })
  }

  const sendEmail = async () => {
    const storageRef = fbConfig.storage().ref()
    const fileRef = storageRef.child(`Invoices/${selectedBatch.batchName}.png`)
    const url = await fileRef.getDownloadURL()

    await axios.post('/api/sendmail/', {
      to: selectedCustomer.email,
      text: `Dear ${
        selectedCustomer && selectedCustomer.name
      }, <br/><br/>Please see attached Invoice for order ${
        selectedBatch && selectedBatch.batchName
      }.<br/><br/>
 You can also download it from here: <a href='${url}'>Download Invoice</a><br/><br/>
         
<p>Thanks & Regards,
            <br />
            Team Avigenics<br/><br/>
                      <img
            style='width: 150px;' height: 80px;' }}
            src='https://avigenics.co.uk/images/logo-6.png'
          /><br/><br/>
E-mail:   info@avigenics.co.uk   |   Phone:   020 3488 8741 <br/>
            Avigenics Limited, 134 Imperial Drive, North Harrow, London, HA2 7HT, UK <br/>
            The contents of this e-mail (including any attachments) may be the proprietary and confidential
          </p>`,
      subject: 'Invoice - ' + selectedBatch.batchName,
      attachments: [
        {
          filename: `${selectedBatch.batchName}.png`,
          path: url,
        },
      ],
    })
  }
  return (
    <>
      <div id='INVrig'>
        <div
          style={{
            backgroundSize: '600px 425px',
            backgroundColor: '#fff',
            minHeight: '425px',
            overflow: 'hidden',
          }}
        >
          <div align='center'>
            <Image
              style={{
                resizeMode: 'cover',
              }}
              src={'/images/AVreceiptlogo.png'}
            />
          </div>
          <br />
          <div
            align='center'
            style={{
              color: '#0028a1',
              paddingLeft: '20px',
              fontFamily: 'Bigshot One',
              fontSize: '25px',
              fontWeight: 'bold',
            }}
          >
            {' '}
            Invoice
          </div>

          <div
            align='center'
            style={{
              paddingLeft: '20px',
              fontFamily: 'Bigshot One',
              fontSize: '15px',
            }}
          >
            {' '}
          </div>
          <br />
          <div
            align='left'
            style={{
              paddingLeft: '20px',
              fontFamily: 'Bahnschrift',
              fontWeight: 'bold',
              fontSize: '15px',
            }}
          >
            {' '}
            Invoice No: {props.values.batchName}{' '}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            Invoice Date:
            {date}
          </div>
          <br />
          <table
            style={{
              marginLeft: '20px',
              fontFamily: 'Bahnschrift',
              fontWeight: 'bold',
              width: '400px',
            }}
          >
            <tr>
              <td
                style={{
                  paddingLeft: '20px',
                  fontFamily: 'Bahnschrift',
                  fontSize: '15px',
                }}
              >
                {' '}
              </td>
            </tr>
            <tr>
              <td
                align='left'
                style={{
                  fontFamily: 'Bahnschrift',
                  fontSize: '14px',
                }}
              >
                Avigenics Limited <br />
                134 Imperial Drive <br />
                North Harrow
                <br />
                London HA2 7HT. <br />
              </td>
              <td>
                {' '}
                <br /> <br /> <br />
                {''}
              </td>
              <td
                align='left'
                style={{
                  paddingLeft: '150px',
                  fontFamily: 'Bahnschrift',
                  fontSize: '14px',
                }}
              >
                <br />
                Bill To: <br />
                {props.values.customerName} <br />
                {selectedCustomer && selectedCustomer.address1}
                <br />
                {selectedCustomer && selectedCustomer.address2}
                <br />
                {selectedCustomer && selectedCustomer.city}
                {'  '}
                {selectedCustomer && selectedCustomer.pincode}
                <br />
                <br />
              </td>
            </tr>
            <tr></tr>
          </table>
          <br />

          <div
            align='left'
            style={{
              // paddingLeft: '20px',
              fontFamily: 'Bahnschrift',
              fontSize: '15px',
              fontWeight: 'bold',
            }}
          ></div>
          <div>
            <table
              align='center'
              style={{
                fontFamily: 'Bahnschrift',
                fontWeight: 'bold',
                width: '100%',
                border: '1px solid black',
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      paddingLeft: '20px',
                      fontSize: '15px',
                      fontFamily: 'Bahnschrift',
                      width: '300px',
                      border: '1px solid black',
                    }}
                  >
                    Item Description
                  </th>

                  <th
                    style={{
                      paddingLeft: '40px',
                      fontSize: '15px',
                      fontFamily: 'Bahnschrift',
                      border: '1px solid black',
                    }}
                  >
                    Quantity
                  </th>
                  <th
                    style={{
                      paddingLeft: '120px',
                      fontSize: '15px',
                      fontFamily: 'Bahnschrift',
                      border: '1px solid black',
                    }}
                  >
                    Amount
                  </th>
                </tr>
              </thead>

              <tbody>
                {dna > 0 && (
                  <tr>
                    <td
                      width='100%'
                      style={{
                        paddingLeft: '20px',
                        fontSize: '13px',
                        fontFamily: 'Bahnschrift',
                        width: '300px',
                      }}
                    >
                      Bird DNA Sexing Test
                    </td>

                    <td
                      style={{
                        paddingLeft: '40px',
                        fontSize: '13px',
                        fontFamily: 'Bahnschrift',
                      }}
                    >
                      {quantity}
                    </td>
                    <td
                      style={{
                        paddingLeft: '120px',
                        fontSize: '13px',
                        fontFamily: 'Bahnschrift',
                      }}
                    >
                      £{dna.toFixed(2)}
                    </td>
                  </tr>
                )}
                {pbfd > 0 && (
                  <tr>
                    <td
                      style={{
                        paddingLeft: '20px',
                        fontSize: '13px',
                        fontFamily: 'Bahnschrift',
                        width: '300px',
                      }}
                    >
                      PBFD Test
                    </td>

                    <td
                      style={{
                        paddingLeft: '40px',
                        fontSize: '13px',
                        fontFamily: 'Bahnschrift',
                      }}
                    >
                      {quantity}
                    </td>
                    <td
                      style={{
                        paddingLeft: '120px',
                        fontSize: '13px',
                        fontFamily: 'Bahnschrift',
                      }}
                    >
                      £{pbfd.toFixed(2)}
                    </td>
                  </tr>
                )}
                {apv > 0 && (
                  <tr>
                    <td
                      style={{
                        paddingLeft: '20px',
                        fontSize: '13px',
                        fontFamily: 'Bahnschrift',
                        width: '300px',
                      }}
                    >
                      APV Test
                    </td>

                    <td
                      style={{
                        paddingLeft: '40px',
                        fontSize: '13px',
                        fontFamily: 'Bahnschrift',
                      }}
                    >
                      {quantity}
                    </td>
                    <td
                      style={{
                        paddingLeft: '120px',
                        fontSize: '13px',
                        fontFamily: 'Bahnschrift',
                      }}
                    >
                      £{apv.toFixed(2)}
                    </td>
                  </tr>
                )}
                {kits > 0 && (
                  <tr>
                    <td
                      style={{
                        paddingLeft: '20px',
                        fontSize: '13px',
                        fontFamily: 'Bahnschrift',
                        width: '300px',
                      }}
                    >
                      Sample Collection Kits
                    </td>

                    <td
                      style={{
                        paddingLeft: '40px',
                        fontSize: '13px',
                        fontFamily: 'Bahnschrift',
                      }}
                    >
                      {quantity}
                    </td>
                    <td
                      style={{
                        paddingLeft: '120px',
                        fontSize: '15px',
                        fontFamily: 'Bahnschrift',
                      }}
                    >
                      £{kits.toFixed(2)}
                    </td>
                  </tr>
                )}
                {cert1 > 0 && (
                  <tr>
                    <td
                      style={{
                        paddingLeft: '20px',
                        fontSize: '13px',
                        fontFamily: 'Bahnschrift',
                        width: '300px',
                      }}
                    >
                      Printed Size Certificates
                    </td>

                    <td
                      style={{
                        paddingLeft: '40px',
                        fontSize: '13px',
                        fontFamily: 'Bahnschrift',
                      }}
                    >
                      {quantity}
                    </td>
                    <td
                      style={{
                        paddingLeft: '120px',
                        fontSize: '13px',
                        fontFamily: 'Bahnschrift',
                      }}
                    >
                      £{cert1.toFixed(2)}
                    </td>
                  </tr>
                )}
                {cert2 > 0 && (
                  <tr>
                    <td
                      style={{
                        paddingLeft: '20px',
                        fontSize: '13px',
                        fontFamily: 'Bahnschrift',
                        width: '300px',
                      }}
                    >
                      Creditcard Size Certificates
                    </td>

                    <td
                      style={{
                        paddingLeft: '40px',
                        fontSize: '13px',
                        fontFamily: 'Bahnschrift',
                      }}
                    >
                      {quantity}
                    </td>
                    <td
                      style={{
                        paddingLeft: '120px',
                        fontSize: '13px',
                        fontFamily: 'Bahnschrift',
                      }}
                    >
                      £{cert2.toFixed(2)}
                    </td>
                  </tr>
                )}
                {postage > 0 && (
                  <tr>
                    <td
                      style={{
                        paddingLeft: '20px',
                        fontSize: '13px',
                        fontFamily: 'Bahnschrift',
                        width: '300px',
                      }}
                    >
                      Postage
                    </td>

                    <td
                      style={{
                        paddingLeft: '40px',
                        fontSize: '13px',
                        fontFamily: 'Bahnschrift',
                      }}
                    >
                      {quantity}
                    </td>
                    <td
                      style={{
                        paddingLeft: '120px',
                        fontSize: '13px',
                        fontFamily: 'Bahnschrift',
                      }}
                    >
                      £{postage.toFixed(2)}
                    </td>
                  </tr>
                )}
                <tr>
                  <td>{''}</td>
                </tr>
              </tbody>
            </table>
            <table
              style={{
                width: '100%',
                borderCollapse: 'collapse',
                marginTop: '0px',
              }}
            >
              <tbody>
                <tr>
                  <td
                    style={{
                      textAlign: 'right',
                      alignSelf: 'right',
                      paddingLeft: '20px',
                      fontSize: '15px',
                      fontFamily: 'Bahnschrift',
                      width: '350px',
                      fontWeight: 'bold',
                    }}
                  >
                    Total Charges
                  </td>

                  <td
                    style={{
                      paddingLeft: '120px',
                      fontSize: '15px',
                      fontFamily: 'Bahnschrift',
                      width: '100px',
                      color: '#0028a1',
                      fontWeight: 'bold',
                    }}
                  >
                    £{props.values.orderCost.toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <br />
          <br />
          <br />
          <div
            align='center'
            style={{
              fontSize: '15px',
              color: '#0028a1',
              fontFamily: 'Bigshot One',
              textAlign: 'center',
            }}
          >
            Thank you for your Business!
          </div>
          <br />
          <br />
          <div
            align='left'
            style={{
              marginLeft: '8px',
              fontsize: '12px',

              fontFamily: 'Bigshot One',
            }}
          >
            {' '}
            Please Transfer to the following Bank Account:
          </div>

          <div
            align='left'
            style={{
              marginLeft: '8px',
              fontsize: '12px',

              fontFamily: 'Bigshot One',
            }}
          >
            Account Name: Avigenics Ltd
            <br />
            Bank Name: Barclays Bank
            <br />
            Sort Code: 20-92-63
            <br />
            Account Number: 33877795
            <br />
          </div>
          <br />

          <hr />
          <div
            style={{
              marginLeft: '8px',
              color: '#0028a1',
              fontFamily: 'Bigshot One',
              textAlign: 'center',
            }}
          >
            <span>
              {' '}
              If you have any questions, visit our support site at
              https://avigenics.co.uk/ContactUs, contact us at
              info@avigenics.co.uk, or call on+44 20 3488 8741.
            </span>
          </div>
        </div>
      </div>

      <button onClick={() => savePdf()}>Save PDF</button>
      <button onClick={() => sendEmail()}>Send Email</button>
    </>
  )
}

export default Invoice
